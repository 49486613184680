import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  List,
  Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTaskData } from '../../hooks/useTaskData';
import TaskItem from './TaskItem';

const TaskList = ({ goalId, onTaskComplete }) => {
  const {
    tasks,
    loading,
    error,
    handleAddTask,
    handleEditTask,
    handleToggleTask,
    handleDeleteTask,
    userId
  } = useTaskData(goalId);

  const [newTaskContent, setNewTaskContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newTaskContent.trim()) {
      await handleAddTask(newTaskContent.trim());
      setNewTaskContent('');
    }
  };

  const handleTaskToggle = async (taskId, isCompleted, content) => {
    const success = await handleToggleTask(taskId, isCompleted);
    if (success && isCompleted) {
      onTaskComplete(content);
    }
  };

  if (loading) return <Typography>読み込み中...</Typography>;
  if (error) return <Typography color="error">エラー: {error}</Typography>;

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        タスク一覧
      </Typography>
      
      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 2, display: 'flex' }}>
        <TextField
          fullWidth
          size="small"
          value={newTaskContent}
          onChange={(e) => setNewTaskContent(e.target.value)}
          placeholder="新しいタスクを追加"
          sx={{ mr: 1 }}
        />
        <IconButton type="submit" color="primary">
          <AddIcon />
        </IconButton>
      </Box>

      <List>
        {tasks.map((task) => (
          <TaskItem
            key={task.id}
            task={task}
            onToggle={handleTaskToggle}
            onEdit={handleEditTask}
            onDelete={handleDeleteTask}
            canEdit={task.user_id === userId}
          />
        ))}
      </List>
    </Paper>
  );
};

export default TaskList;