/**
 * @fileoverview 直近の振り返りを表示し、フィルタリングするコンポーネント
 * @module RecentReflections
 */

import React, { useState, useMemo, useEffect } from 'react';
import { Typography, Paper, Card, CardContent, Button, Box, Grid, Chip, Select, MenuItem, FormControl, InputLabel, styled, useMediaQuery, useTheme, Avatar, IconButton, Menu, ListItemIcon, ListItemText } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { ReferenceLinks } from '../../utils/commonUtils';
import { useProjectData } from '../../hooks/useProjectData';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useReflectionData } from '../../hooks/useReflectionData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReflectionDialog from './ReflectionDialog';
import ReflectionRemoveDialog from './ReflectionRemoveDialog';
import WorkIcon from '@mui/icons-material/Work';
import LinkIcon from '@mui/icons-material/Link';

/**
 * スタイル付きのカードコンポーネント
 * @type {import('@mui/material').StyledComponent}
 */
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

/**
 * スタイル付きのアバターコンポーネント
 * @type {import('@mui/material').StyledComponent}
 */
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: theme.spacing(5),
  height: theme.spacing(5),
}));

/**
 * 個々の振り返りカードを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.reflection - 振り返りデータ
 * @param {string} props.reflection.goals.title - 目標のタイトル
 * @param {string} props.reflection.date - 振り返りの日付
 * @param {string} props.reflection.start_time - 開始時間
 * @param {string} props.reflection.end_time - 終了時間
 * @param {number} props.reflection.actual_value - 実際の達成値
 * @param {Object} props.reflection.goals.units - 単位情報
 * @param {string} props.reflection.achievement - 達成したこと
 * @param {string} props.reflection.learning - 学んだこと
 * @param {string} props.reflection.improvement - 改善点
 * @param {Array<Object>} props.reflection.reference_links - 参考リンク
 * @param {Array<Object>} props.reflection.skills - 関連スキル
 * @returns {React.ReactElement} 振り返りカードのJSX
 */
const ReflectionCard = ({ reflection, onEdit, onDelete }) => {
  /**
   * 時間文字列をフォーマットする
   * @param {string} timeString - フォーマットする時間文字列
   * @returns {string} フォーマットされた時間文字列
   */
  const formatTime = (timeString) => {
    if (!timeString) return '';
    const date = new Date(timeString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(reflection);
    handleMenuClose();
  };

  const handleDelete = () => {
    onDelete(reflection);
    handleMenuClose();
  };

  return (
    <StyledCard elevation={2}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledAvatar>
              {reflection.goals.title.charAt(0).toUpperCase()}
            </StyledAvatar>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {reflection.goals.title}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {new Date(reflection.date).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
          <IconButton size="small" onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleEdit}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="編集" />
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="削除" />
            </MenuItem>
          </Menu>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary">
            {formatTime(reflection.start_time)} ~ {formatTime(reflection.end_time)}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            実績: {reflection.actual_value ? `${reflection.actual_value} ${reflection.goals.units.name}` : '-'}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <EmojiEventsIcon fontSize="small" sx={{ mr: 1 }} /> 達成したこと:
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            <ReactMarkdown>{reflection.achievement}</ReactMarkdown>
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <EmojiObjectsIcon fontSize="small" sx={{ mr: 1 }} /> 学んだこと:
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            <ReactMarkdown>{reflection.learning}</ReactMarkdown>
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <TrendingUpIcon fontSize="small" sx={{ mr: 1 }} /> 次にやること・改善点:
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            <ReactMarkdown>{reflection.improvement}</ReactMarkdown>
          </Typography>
        </Box>

        {/* 成果物セクション - 条件付きレンダリング */}
        {reflection.artifacts && reflection.artifacts.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
              <WorkIcon fontSize="small" sx={{ mr: 1 }} /> 成果物:
            </Typography>
            <ReferenceLinks links={reflection.artifacts} />
          </Box>
        )}

        {/* 参考リンクセクション - 条件付きレンダリング */}
        {reflection.reference_links && reflection.reference_links.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
              <LinkIcon fontSize="small" sx={{ mr: 1 }} /> 参考リンク:
            </Typography>
            <ReferenceLinks links={reflection.reference_links} />
          </Box>
        )}

        <Box sx={{ mt: 'auto' }}>
          {reflection.skills && reflection.skills.map((skill) => (
            <Chip key={skill.id} label={skill.name} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
          ))}
        </Box>
      </CardContent>
    </StyledCard>
  );
};

/**
 * 直近の振り返りを表示し、フィルタリングするコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array<Object>} props.reflections - 振り返りデータの配
 * @param {string} [props.defaultProjectFilter] - デフォルトのプロジェクトフィルター
 * @param {string} [props.defaultGoalFilter] - デフォルトのゴールフィルター
 * @returns {React.ReactElement} 振り返り一覧のJSX
 */
const RecentReflections = ({ defaultProjectFilter, defaultGoalFilter, refreshTrigger }) => {
  const { reflections, loading, error, fetchReflections, handleDeleteReflection } = useReflectionData(defaultGoalFilter, defaultProjectFilter);
  const { projects } = useProjectData();
  const [displayCount, setDisplayCount] = useState(4);
  const [projectFilter, setProjectFilter] = useState(defaultProjectFilter || '');
  const [goalFilter, setGoalFilter] = useState(defaultGoalFilter || '');
  const [skillFilter, setSkillFilter] = useState('');
  const [unitFilter, setUnitFilter] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [editingReflection, setEditingReflection] = useState(null);
  const [deletingReflection, setDeletingReflection] = useState(null);

  useEffect(() => {
    fetchReflections();
  }, [refreshTrigger]); // refreshTriggerが変更されたときにfetchReflectionsを呼び出す

  /**
   * 表示する振り返りの数を増やす
   */
  const handleShowMore = () => {
    setDisplayCount(prevCount => prevCount + 8);
  };

  /**
   * プロジェクトオプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const projectOptions = useMemo(() => {
    return projects.map(project => ({
      id: project.id,
      name: project.name
    }));
  }, [projects]);

  /**
   * ゴールオプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const goalOptions = useMemo(() => {
    return [...new Set(reflections.map(r => r.goals?.id).filter(Boolean))].map(id => ({
      id,
      name: reflections.find(r => r.goals?.id === id)?.goals?.title || 'Unknown'
    }));
  }, [reflections]);

  /**
   * スキルオプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const skillOptions = useMemo(() => {
    const skills = reflections.flatMap(r => r.skills || []);
    return [...new Set(skills.map(s => s?.id).filter(Boolean))].map(id => ({
      id,
      name: skills.find(s => s?.id === id)?.name || 'Unknown'
    }));
  }, [reflections]);

  /**
   * 単位オプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const unitOptions = useMemo(() => {
    return [...new Set(reflections.map(r => r.goals?.unit_id).filter(Boolean))].map(id => ({
      id,
      name: reflections.find(r => r.goals?.unit_id === id)?.goals?.units?.name || 'Unknown'
    }));
  }, [reflections]);

  /**
   * フィルタリングされた振り返りを取得する
   * @type {Array<Object>}
   */
  const filteredReflections = useMemo(() => {
    return reflections.filter(reflection => 
      (!projectFilter || reflection.goals?.project_id === projectFilter) &&
      (!goalFilter || reflection.goals?.id === goalFilter) &&
      (!skillFilter || reflection.skills?.some(skill => skill?.id === skillFilter)) &&
      (!unitFilter || reflection.goals?.unit_id === unitFilter)
    );
  }, [reflections, projectFilter, goalFilter, skillFilter, unitFilter]);

  const handleEditReflection = (reflection) => {
    setEditingReflection(reflection);
  };

  const handleSaveReflection = async (updatedReflection) => {
    await handleEditReflection(updatedReflection);
    setEditingReflection(null);
    fetchReflections();
  };

  const handleDeleteClick = (reflection) => {
    setDeletingReflection(reflection);
  };

  const handleConfirmDelete = async () => {
    if (deletingReflection) {
      try {
        await handleDeleteReflection({ id: deletingReflection.id });
        setDeletingReflection(null);
        fetchReflections();
      } catch (error) {
        console.error('振り返りの削除に失敗しました:', error);
        alert('振り返りの削除中にエラーが発生しました。');
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>直近の振り返り</Typography>
      
      <Box sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        gap: 2,
        mb: 2,
        overflowX: isSmallScreen ? 'auto' : 'visible',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.grey[300],
          borderRadius: '4px',
        },
      }}>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>プロジェクト</InputLabel>
          <Select value={projectFilter} onChange={(e) => setProjectFilter(e.target.value)} label="プロジェクト">
            <MenuItem value="">全て</MenuItem>
            {projectOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>ゴール</InputLabel>
          <Select value={goalFilter} onChange={(e) => setGoalFilter(e.target.value)} label="ゴール">
            <MenuItem value="">全て</MenuItem>
            {goalOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>スキル</InputLabel>
          <Select value={skillFilter} onChange={(e) => setSkillFilter(e.target.value)} label="スキル">
            <MenuItem value="">全て</MenuItem>
            {skillOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>単位</InputLabel>
          <Select value={unitFilter} onChange={(e) => setUnitFilter(e.target.value)} label="単位">
            <MenuItem value="">全て</MenuItem>
            {unitOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2}>
        {filteredReflections.slice(0, displayCount).map((reflection) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={reflection.id}>
            <ReflectionCard
              reflection={reflection}
              onEdit={handleEditReflection}
              onDelete={() => handleDeleteClick(reflection)}
            />
          </Grid>
        ))}
      </Grid>
      {displayCount < filteredReflections.length && (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Button variant="outlined" onClick={handleShowMore}>
            さらに表示する
          </Button>
        </Box>
      )}

      {editingReflection && (
        <ReflectionDialog
          goal={editingReflection.goals}
          reflection={editingReflection}
          onClose={() => setEditingReflection(null)}
          onSave={handleSaveReflection}
          open={Boolean(editingReflection)}
        />
      )}

      {deletingReflection && (
        <ReflectionRemoveDialog
          reflections={[deletingReflection.id]}
          onClose={() => setDeletingReflection(null)}
          onRemove={handleConfirmDelete}
          onActivityChange={fetchReflections}
        />
      )}
    </Paper>
  );
};

export default RecentReflections;
