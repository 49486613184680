/**
 * @file Navigation.js
 * @description アプリケーションのナビゲーションメニューを表示するReactコンポーネント
 * 
 * このコンポーネントは、サイドバーナビゲーションメニューを実装します。
 * ダッシュボードやプロフィールなどの主要なページへのリンクを提供します。
 * 
 * @requires React
 * @requires react-router-dom
 * @requires @mui/material
 * @requires @mui/icons-material
 */

import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FlagIcon from '@mui/icons-material/Flag';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import queryString from 'query-string';

/**
 * Navigationは、サイドバーナビゲーションメニューを表示するReactコンポーネントです。
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.isOpen - ナビゲーションメニューが開いているかどうか
 * @param {Function} props.toggleMenu - メニューの開閉を切り替える関数
 * @returns {JSX.Element} ナビゲーションメニューを表示するDrawer要素
 */
const Navigation = ({ isOpen, toggleMenu }) => {
  const location = useLocation();
  const { goal: goalId } = queryString.parse(location.search);

  const menuItems = [
    { 
      text: 'ダッシュボード', 
      path: goalId ? `/?goal=${goalId}` : '/', 
      icon: <DashboardIcon /> 
    },
    { 
      text: 'プロフィール', 
      path: goalId ? `/profile?goal=${goalId}` : '/profile', 
      icon: <PersonIcon /> 
    },
  ];

  return (
    <Drawer anchor="left" open={isOpen} onClose={toggleMenu}>
      <List>
        {menuItems.map((item) => (
          <ListItem 
            button 
            key={item.text} 
            component={RouterLink} 
            to={item.path} 
            onClick={toggleMenu}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Navigation;
