/**
 * @file GoalProgress.js
 * @description 目標の進捗状況を表示するためのReactコンポーネント
 * 
 * このコンポーネントは、目標の進捗状況をプログレスバーとパーセンテージで表示します。
 * 実績が目標を超えた場合、超過分のパーセントも表示します。
 * 進捗率に応じて色が変化します。
 * 
 * @requires React
 * @requires @mui/material
 */

import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

/**
 * GoalProgressは、目標の進捗状況を表示するReactコンポーネントです。
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.goal - 目標オブジェクト
 * @param {boolean} [props.showPercentage=true] - パーセンテージを表示するかどうか
 * @param {number} [props.height=4] - プログレスバーの高さ
 * @returns {JSX.Element} 進捗状況を表示するBox要素
 */
const GoalProgress = ({ goal, showPercentage = true, height = 4 }) => {
  const targetValue = parseFloat(goal.target_value) || 0;
  const totalActualValue = goal.totalActualValue || 0;

  const calculateProgress = () => {
    return targetValue > 0 ? (totalActualValue / targetValue) * 100 : 0;
  };

  const progress = calculateProgress();

  // 進捗率に応じて色を決定する関数
  const getColorByProgress = (progress) => {
    if (progress < 50) return '#0131b4'; // 青
    if (progress < 80) return '#008000'; // 緑
    if (progress < 100) return '#ffa500'; // オレンジ
    return '#ff0000'; // 赤（100%以上）
  };

  const progressColor = getColorByProgress(progress);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
      <Box sx={{ flexGrow: 1, mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={Math.min(progress, 100)}
          sx={{
            height: height,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: progressColor,
            },
          }}
        />
      </Box>
      {showPercentage && (
        <Typography variant="caption" sx={{ minWidth: '40px', textAlign: 'right' }}>
          {`${Math.round(progress)}%`}
        </Typography>
      )}
    </Box>
  );
};

export default GoalProgress;
