/**
 * @fileoverview 振り返り要約生成コンポーネント
 */

import React from 'react';
import { goalSummaryService } from '../../services/summaryService';
import { openaiService } from '../../services/openaiService';
import SummaryGenerator from '../common/SummaryGenerator';

const ReflectionSummaryGenerator = ({ goalId, reflections }) => {
  const generateSummary = async (model, userInstructions) => {
    return await openaiService.generateSummary(reflections, model, userInstructions);
  };

  return (
    <SummaryGenerator
      title="振り返りの要約"
      summaryService={goalSummaryService}
      targetId={goalId}
      generateSummary={generateSummary}
      disabled={reflections.length === 0}
    //   defaultInstructions="特に重要な学びや改善点に焦点を当ててください"
    />
  );
};

export default ReflectionSummaryGenerator;
