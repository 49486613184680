/**
 * @fileoverview 要約データを管理する共通カスタムフック
 */

import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';

export const useSummary = (targetId, summaryService) => {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) setUserId(user.id);
    };
    fetchUserId();
  }, []);

  const fetchSummaries = async () => {
    setLoading(true);
    try {
      // プロフィール要約の場合、targetIdの代わりにuserIdを使用
      const actualTargetId = summaryService.tableName === 'profile_summaries' ? userId : targetId;
      if (!actualTargetId) return;

      const data = await summaryService.fetchSummaries(actualTargetId);
      setSummaries(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const createSummary = async (content, model, userInstructions) => {
    try {
      // プロフィール要約の場合、targetIdの代わりにuserIdを使用
      const actualTargetId = summaryService.tableName === 'profile_summaries' ? userId : targetId;
      if (!actualTargetId) throw new Error('ターゲットIDが見つかりません');

      await summaryService.createSummary({
        targetId: actualTargetId,
        content,
        model,
        userInstructions
      });
      await fetchSummaries();
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (summaryService.tableName === 'profile_summaries' ? userId : targetId) {
      fetchSummaries();
    }
  }, [targetId, userId]);

  return {
    summaries,
    loading,
    error,
    createSummary,
    fetchSummaries
  };
};
