/**
 * @fileoverview 振り返りの要約を表示するカードコンポーネント
 * @module ReflectionSummaryCard
 * @requires React
 * @requires @mui/material
 * @requires ../../hooks/useReflectionData
 * @requires ../../hooks/useGoalData
 * @requires ../../hooks/useActivityData
 * @requires ../../styles/tableStyles
 * @requires ../../utils/commonUtils
 */

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Button, Box } from '@mui/material';
import { useReflectionData } from '../../hooks/useReflectionData';
import { useGoalData } from '../../hooks/useGoalData';
import { useActivityData } from '../../hooks/useActivityData';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Chip } from '@mui/material';
import { StyledTableCell } from '../../styles/tableStyles';
import { ReferenceLinks } from '../../utils/commonUtils';
import { IconButton, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

/**
 * 振り返りの要約を表示するカードコンポーネント
 * @function ReflectionSummaryCard
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string|null} [props.goalID=null] - 目標ID
 * @param {string|null} [props.projectID=null] - プロジェクトID
 * @param {boolean} [props.showDetails=false] - 詳細表示フラグ
 * @param {Function} props.onEditReflection - 振り返り編集ハンドラ
 * @param {Function} props.onDeleteReflections - 振り返り削除ハンドラ
 * @returns {React.Component} ReflectionSummaryCardコンポーネント
 */
const ReflectionSummaryCard = ({ goalID = null, projectID = null, showDetails = false, onEditReflection, onDeleteReflections }) => {
  const { userId, reflections, loading: reflectionsLoading, error: reflectionsError } = useReflectionData(goalID, projectID);
  const { completedGoals, loading: goalsLoading, error: goalsError } = useGoalData(projectID);
  const { activities, loading: activitiesLoading, error: activitiesError } = useActivityData(goalID, projectID);
  const [summaryData, setSummaryData] = useState({
    totalReflections: 0,
    totalDuration: 0,
    completedGoals: 0,
    averageReflectionTime: 0,
    achievementSummary: '',
    learningSummary: '',
    improvementSummary: '',
  });
  const [expandedTable, setExpandedTable] = useState(false);
  const [displayedActivities, setDisplayedActivities] = useState([]);
  const [selectedReflections, setSelectedReflections] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');

  useEffect(() => {
    if (userId && reflections.length > 0) {
      processSummaryData();
    }
    if (activities.length > 0) {
      const reflectionActivities = activities
        .filter(activity => activity.type === 'reflection')
        .sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
      setDisplayedActivities(expandedTable ? reflectionActivities : reflectionActivities.slice(0, 5));
    }
  }, [userId, reflections, completedGoals, activities, expandedTable, sortOrder]);

  useEffect(() => {
    if (activities.length > 0) {
      const reflectionActivities = activities
        .filter(activity => activity.type === 'reflection')
        .sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
      setDisplayedActivities(expandedTable ? reflectionActivities : reflectionActivities.slice(0, 5));
    }
  }, [activities, expandedTable, sortOrder]);

  /**
   * 要約データを処理する
   * @function processSummaryData
   */
  const processSummaryData = () => {
    const totalReflections = reflections.length;
    const totalDuration = reflections.reduce((sum, item) => sum + item.reflection_duration, 0);
    const averageReflectionTime = totalReflections > 0 ? totalDuration / totalReflections : 0;

    const achievementSummary = reflections.map(r => r.achievement).filter(Boolean).join('\n');
    const learningSummary = reflections.map(r => r.learning).filter(Boolean).join('\n');
    const improvementSummary = reflections.map(r => r.improvement).filter(Boolean).join('\n');

    setSummaryData({
      totalReflections,
      totalDuration,
      completedGoals: completedGoals.length,
      averageReflectionTime,
      achievementSummary,
      learningSummary,
      improvementSummary,
    });
  };

  /**
   * テキストを改行付きで整形する
   * @function formatText
   * @param {string} text - 整形するテキスト
   * @returns {React.Fragment} 整形されたテキスト
   */
  const formatText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  /**
   * 時間文字列を整形する
   * @function formatTime
   * @param {string} timeString - 整形する時間文字列
   * @returns {string} 整形された時間文字列
   */
  const formatTime = (timeString) => {
    if (!timeString) return '';
    const date = new Date(timeString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  /**
   * テーブルの展開/縮小を切り替える
   * @function handleExpandTable
   */
  const handleExpandTable = () => {
    setExpandedTable(!expandedTable);
  };

  /**
   * チェックボックスの状態を変更する
   * @function handleCheckboxChange
   * @param {string} activityId - アクティビティID
   */
  const handleCheckboxChange = (activityId) => {
    setSelectedReflections(prev => 
      prev.includes(activityId) 
        ? prev.filter(id => id !== activityId)
        : [...prev, activityId]
    );
  };

  /**
   * 編集ボタンのクリックハンドラ
   * @function handleEditClick
   */
  const handleEditClick = () => {
    if (selectedReflections.length === 1) {
      const reflectionToEdit = displayedActivities.find(activity => activity.id === selectedReflections[0]);
      onEditReflection(reflectionToEdit);
    }
  };

  /**
   * 削除ボタンのクリックハンドラ
   * @function handleDeleteClick
   */
  const handleDeleteClick = () => {
    if (selectedReflections.length > 0) {
      onDeleteReflections(selectedReflections);
    }
  };

  /**
   * ソート順を切り替える
   * @function handleSortToggle
   */
  const handleSortToggle = () => {
    setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
  };

  if (activitiesLoading || reflectionsLoading || goalsLoading) return <Typography>読み込み中...</Typography>;
  if (activitiesError || reflectionsError || goalsError) return <Typography color="error">エラー: {activitiesError || reflectionsError || goalsError}</Typography>;

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          振り返りまとめ
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <Typography variant="body2" color="text.secondary">
              総振り返り回数
            </Typography>
            <Typography variant="h4">
              {summaryData.totalReflections}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="body2" color="text.secondary">
              総振り返り時間
            </Typography>
            <Typography variant="h4">
              {Math.round(summaryData.totalDuration / 60)}時間
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="body2" color="text.secondary">
              達成目標数
            </Typography>
            <Typography variant="h4">
              {summaryData.completedGoals}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="body2" color="text.secondary">
              平均振り返り時間
            </Typography>
            <Typography variant="h4">
              {Math.round(summaryData.averageReflectionTime)}分
            </Typography>
          </Grid>
        </Grid>
        {showDetails && (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
              <IconButton onClick={handleEditClick} disabled={selectedReflections.length !== 1}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleDeleteClick} disabled={selectedReflections.length === 0}>
                <DeleteIcon />
              </IconButton>
            </Box>
            <Box sx={{ overflowX: 'auto' }}>
              <Box sx={{ minWidth: '800px' }}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            indeterminate={selectedReflections.length > 0 && selectedReflections.length < displayedActivities.length}
                            checked={selectedReflections.length === displayedActivities.length}
                            onChange={() => {
                              if (selectedReflections.length === displayedActivities.length) {
                                setSelectedReflections([]);
                              } else {
                                setSelectedReflections(displayedActivities.map(activity => activity.id));
                              }
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          日付
                          <IconButton size="small" onClick={handleSortToggle}>
                            {sortOrder === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell>時間</StyledTableCell>
                        <StyledTableCell>実績</StyledTableCell> {/* 新しい列を追加 */}
                        <StyledTableCell>達成したこと</StyledTableCell>
                        <StyledTableCell>学んだこと</StyledTableCell>
                        <StyledTableCell>改善点・次にやること</StyledTableCell>
                        <StyledTableCell>実施時間</StyledTableCell>
                        <StyledTableCell>スキル</StyledTableCell>
                        <StyledTableCell>参考リンク</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedActivities.map((activity) => (
                        <TableRow key={activity.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedReflections.includes(activity.id)}
                              onChange={() => handleCheckboxChange(activity.id)}
                            />
                          </TableCell>
                          <TableCell align="center">{new Date(activity.date).toLocaleDateString()}</TableCell>
                          <TableCell align="center">
                            {formatTime(activity.start_time)} ~ {formatTime(activity.end_time)}
                          </TableCell>
                          <TableCell align="center">{activity.actual_value || '-'}</TableCell> {/* 新しい列を追加 */}
                          <TableCell>{formatText(activity.achievement)}</TableCell>
                          <TableCell>{formatText(activity.learning)}</TableCell>
                          <TableCell>{formatText(activity.improvement)}</TableCell>
                          <TableCell align="center">{`${Math.floor(activity.reflection_duration / 60)}時間${activity.reflection_duration % 60}分`}</TableCell>
                          <TableCell>
                            {activity.skills && activity.skills.map((skill) => (
                              <Chip key={skill.id} label={skill.name} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                            ))}
                          </TableCell>
                          <TableCell>
                            <ReferenceLinks links={activity.reference_links} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {activities.filter(activity => activity.type === 'reflection').length > 5 && (
                  <Button 
                    onClick={handleExpandTable} 
                    sx={{ mt: 2 }}
                  >
                    {expandedTable ? '表示を縮小' : 'すべて表示'}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ReflectionSummaryCard;
