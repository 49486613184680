/**
 * @fileoverview 時間範囲選択コンポーネント
 * @module TimeRangeSelector
 */

import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

/**
 * 時間範囲を選択するためのコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.timeRange - 現在選択されている時間範囲
 * @param {Function} props.onTimeRangeChange - 時間範囲変更時のコールバック関数
 * @returns {React.Component} TimeRangeSelectorコンポーネント
 */
const TimeRangeSelector = ({ timeRange, onTimeRangeChange }) => {
  return (
    <ToggleButtonGroup
      value={timeRange}
      exclusive
      onChange={onTimeRangeChange}
      aria-label="時間範囲"
      size="small"
      fullWidth
    >
      <ToggleButton value="daily" aria-label="日次">
        日次
      </ToggleButton>
      <ToggleButton value="weekly" aria-label="週次">
        週次
      </ToggleButton>
      <ToggleButton value="monthly" aria-label="月次">
        月次
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TimeRangeSelector;
