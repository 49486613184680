/**
 * @file GoalTooltip.js
 * @description 目標の詳細情報を表示するためのReactコンポーネント
 * 
 * このコンポーネントは、目標の詳細情報をツールチップとして表示します。
 * 目標のタイトル、概要、期限、状態、進捗状況などの情報を含みます。
 * 
 * @requires React
 * @requires @mui/material
 * @requires date-fns
 * @requires ../../utils/commonUtils
 */

import React from 'react';
import { Typography, Box } from '@mui/material';
import { parseISO, isValid, format } from 'date-fns';
import { formatNumber } from '../../utils/commonUtils';

/**
 * 日付文字列をフォーマットする関数
 * 
 * @param {string} dateString - フォーマットする日付文字列
 * @returns {string} フォーマットされた日付文字列
 */
const formatDate = (dateString) => {
  if (!dateString) return '日付未設定';
  const date = parseISO(dateString);
  return isValid(date) ? format(date, 'yyyy/MM/dd') : '無効な日付';
};

/**
 * 目標の状態を取得する関数
 * 
 * @param {Object} goal - 目標オブジェクト
 * @returns {string} 目標の状態
 */
const getGoalStatus = (goal) => {
  if (goal.is_completed) {
    return '完了';
  }
  return goal.status === 'in_progress' ? '進行中' : '未着手';
};

/**
 * 進捗状況を表示するかどうかを判断する関数
 * 
 * @param {Object} goal - 目標オブジェクト
 * @returns {boolean} 進捗状況を表示するかどうか
 */
const shouldShowProgress = (goal) => {
  return goal.target_value && parseFloat(goal.target_value) > 0;
};

/**
 * GoalTooltipは、目標の詳細情報を表示するReactコンポーネントです。
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.goal - 目標オブジェクト
 * @returns {JSX.Element} 目標の詳細情報を表示するBox要素
 */
const GoalTooltip = ({ goal }) => (
  <Box sx={{ maxWidth: '300px' }}>
    <Typography variant="body2"><strong>目標:</strong> {goal.title}</Typography>
    <Typography variant="body2" component="div">
      <strong>概要:</strong>{' '}
      <div style={{ 
        whiteSpace: 'pre-wrap', 
        fontFamily: 'inherit', 
        fontSize: 'inherit',
        marginTop: '4px'
      }}>
        {goal.description || '概要なし'}
      </div>
    </Typography>
    <Typography variant="body2"><strong>期限:</strong> {formatDate(goal.deadline)}</Typography>
    <Typography variant="body2"><strong>状態:</strong> {getGoalStatus(goal)}</Typography>
    {shouldShowProgress(goal) && (
      <Typography variant="body2">
        <strong>進捗:</strong> {formatNumber(goal.totalActualValue || 0)} / {formatNumber(parseFloat(goal.target_value))} {goal.unit_name}
      </Typography>
    )}
  </Box>
);

export default GoalTooltip;
