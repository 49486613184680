import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  Box, 
  Typography, 
  IconButton,
  Paper,
  Tooltip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { handleAddLink, handleLinkChange, handleRemoveLink } from '../../utils/commonUtils';
import { MDXEditor } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { getMDXEditorPlugins, getMDXEditorStyles } from '../../utils/mdxEditorConfig';

/**
 * プロジェクトの作成・編集を行うダイアログコンポーネント
 * 
 * @component
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object|null} props.project - 編集するプロジェクトの情報（新規作成時はnull）
 * @param {function} props.onClose - ダイアログを閉じる際のコールバック関数
 * @param {function} props.onSave - プロジェクト保存時のコールバック関数
 * @returns {React.Element} プロジェクト作成・編集ダイアログコンポーネント
 */
const ProjectDialog = ({ project = null, onClose, onSave }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [referenceLinks, setReferenceLinks] = useState(project && project.reference_links ? project.reference_links : [{ url: '', title: '' }]);

  useEffect(() => {
    if (project) {
      setName(project.name);
      setDescription(project.description || '');
      setStartDate(project.start_date ? new Date(project.start_date) : null);
      setEndDate(project.end_date ? new Date(project.end_date) : null);
    }
  }, [project]);

  /**
   * フォームのバリデーションを行う
   * @returns {boolean} バリデーション結果
   */
  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = 'プロジェクト名は必須です';
    if (startDate && endDate && startDate > endDate) {
      newErrors.endDate = '終了日は開始日より後の日付を選択してください';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /**
   * フォーム送信時の処理
   * @param {Event} e - フォーム送信イベント
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSave({
        name,
        description,
        start_date: startDate,
        end_date: endDate,
        reference_links: referenceLinks.filter(link => link.url),
      });
    }
  };

  const mdxPlugins = getMDXEditorPlugins();
  const mdxStyles = getMDXEditorStyles();

  const sectionStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    borderRadius: 1,
    p: 2,
    mb: 3,
  };

  const sectionHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mb: 2,
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{project ? 'プロジェクトの編集' : '新しいプロジェクト'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {/* 基本情報セクション */}
          <Paper sx={sectionStyle}>
            <Box sx={sectionHeaderStyle}>
              <Typography variant="h6">基本情報</Typography>
              <Tooltip title="プロジェクトの基本的な情報を入力してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Box>
            <TextField
              fullWidth
              label="プロジェクト名"
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              size="small"
              error={!!errors.name}
              helperText={errors.name}
              required
            />
            <Box sx={{ mt: 2 }}>
              <DatePicker
                label="開始日"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                format="yyyy/MM/dd"
                slotProps={{ 
                  textField: { 
                    fullWidth: true, 
                    margin: 'normal',
                    size: 'small'
                  } 
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DatePicker
                label="終了日"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                format="yyyy/MM/dd"
                slotProps={{ 
                  textField: { 
                    fullWidth: true, 
                    margin: 'normal',
                    size: 'small',
                    error: !!errors.endDate,
                    helperText: errors.endDate
                  } 
                }}
              />
            </Box>
          </Paper>

          {/* プロジェクト概要セクション */}
          <Paper sx={sectionStyle}>
            <Box sx={sectionHeaderStyle}>
              <Typography variant="h6">プロジェクト概要</Typography>
              <Tooltip title="プロジェクトの目的や概要を詳しく記述してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Box>
            <Box sx={mdxStyles}>
              <MDXEditor
                markdown={description}
                onChange={(value) => setDescription(value)}
                plugins={mdxPlugins}
                contentEditableClassName="prose max-w-full"
              />
            </Box>
          </Paper>

          {/* 参考リンクセクション */}
          <Paper sx={sectionStyle}>
            <Box sx={sectionHeaderStyle}>
              <Typography variant="h6">参考リンク</Typography>
              <Tooltip title="プロジェクトに関連する参考資料やWebサイトのURLを追加してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Box>
            {referenceLinks.map((link, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <TextField
                  label="URL"
                  value={link.url}
                  onChange={(e) => handleLinkChange(index, 'url', e.target.value, referenceLinks, setReferenceLinks)}
                  size="small"
                  fullWidth
                  sx={{ mr: 1 }}
                />
                <TextField
                  label="タイトル"
                  value={link.title}
                  onChange={(e) => handleLinkChange(index, 'title', e.target.value, referenceLinks, setReferenceLinks)}
                  size="small"
                  fullWidth
                  sx={{ mr: 1 }}
                />
                <IconButton onClick={() => handleRemoveLink(index, referenceLinks, setReferenceLinks)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              startIcon={<LinkIcon />}
              onClick={() => handleAddLink(setReferenceLinks)}
              variant="outlined"
              size="small"
              fullWidth
            >
              リンクを追加
            </Button>
          </Paper>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color="primary"
          disabled={!name.trim()}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectDialog;
