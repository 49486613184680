/**
 * @fileoverview 目標データを取得・管理するカスタムフック
 * @module useGoalData
 * @requires react
 * @requires ../utils/supabase
 * @requires ../services/goalService
 */

import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { goalService } from '../services/goalService';

/**
 * 目標データを取得・管理するカスタムフック
 * @function useGoalData
 * @param {string|null} [projectId=null] - プロジェクトID
 * @param {string|null} [goalId=null] - 目標ID
 * @returns {Object} 目標データ、完了済み目標、ローディング状態、エラー状態、データ操作関数を含むオブジェクト
 */
export const useGoalData = (projectId = null, goalId = null) => {
  const [userId, setUserId] = useState(null);
  const [goals, setGoals] = useState([]);
  const [completedGoals, setCompletedGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) setUserId(user.id);
    };
    fetchUserId();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchGoals();
    }
  }, [userId, projectId, goalId]);

  /**
   * 目標データを取得する
   * @async
   * @function fetchGoals
   */
  const fetchGoals = async () => {
    setLoading(true);
    setError(null);
    try {
      if (goalId) {
        const goalData = await goalService.fetchGoals(userId, null, goalId, projectId);
        setGoals(goalData);
      } else {
        const [activeGoals, completedGoalsData] = await Promise.all([
          goalService.fetchGoals(userId, false, null, projectId),
          goalService.fetchGoals(userId, true, null, projectId)
        ]);
        setGoals(activeGoals);
        setCompletedGoals(completedGoalsData);
      }
    } catch (err) {
      console.error('目標データの取得に失敗しました:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * 目標を編集する
   * @async
   * @function handleEditGoal
   * @param {Object} updatedGoal - 更新された目標データ
   */
  const handleEditGoal = async (updatedGoal) => {
    try {
      await goalService.updateGoal(updatedGoal.id, updatedGoal);
      await fetchGoals();
    } catch (error) {
      console.error('目標の更新に失敗しました:', error);
      setError(error.message);
    }
  };

  /**
   * 目標を削除する
   * @async
   * @function handleDeleteGoal
   * @param {Object} goalToDelete - 削除する目標データ
   */
  const handleDeleteGoal = async (goalToDelete) => {
    try {
      await goalService.deleteGoal(goalToDelete.id);
      await fetchGoals();
    } catch (error) {
      console.error('目標の削除に失敗しました:', error);
      setError(error.message);
    }
  };

  /**
   * 目標のステータスを更新する
   * @async
   * @function handleStatusChange
   * @param {Object} goal - 目標オブジェクト
   * @param {string} newStatus - 新しいステータス
   */
  const handleStatusChange = async (goal, newStatus) => {
    try {
      await goalService.updateGoalStatus(goal.id, newStatus);
      await fetchGoals();
    } catch (error) {
      console.error('ステータスの更新に失敗しました:', error);
      setError(error.message);
    }
  };

  return {
    goals,
    completedGoals,
    loading,
    error,
    fetchGoals,
    handleEditGoal,
    handleDeleteGoal,
    handleStatusChange,
  };
};
