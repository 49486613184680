/**
 * @fileoverview 日々の活動を表示するチャートコンポーネント
 * @module DailyActivityChart
 */

import React, { useState, useMemo } from 'react';
import { Box, Typography, Paper, Tooltip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, parseISO, isWithinInterval, eachDayOfInterval, startOfDay, endOfDay, subDays } from 'date-fns';
import { addMinutes } from 'date-fns';
import { ja } from 'date-fns/locale';
import DateRangePicker from './common/DateRangePicker';
import ReflectionDialog from '../reflections/ReflectionDialog';
import ReflectionRemoveDialog from '../reflections/ReflectionRemoveDialog';
import { useReflectionData } from '../../hooks/useReflectionData';

/**
 * 日々の活動を表示するチャートコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.activities - 活動データの配列
 * @param {Function} props.onActivityChange - 活動変更時のコールバック関数
 * @param {string} props.selectedProject - 選択されたプロジェクトのID
 * @returns {JSX.Element} DailyActivityChartコンポーネント
 */
const DailyActivityChart = ({ activities, onActivityChange, selectedProject }) => {
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(subDays(endDate, 4));
  const [selectedReflection, setSelectedReflection] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const { handleEditReflection, handleDeleteReflection } = useReflectionData();

  /**
   * 日付の変更を処理する
   * @param {Event} e - 日付変更イベント
   * @param {boolean} isStart - 開始日かどうか
   */
  const handleDateChange = (e, isStart) => {
    const date = new Date(e.target.value);
    if (isStart) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const filteredActivities = useMemo(() => {
    return activities.filter(activity => {
      if (activity.type === 'reflection') {
        const activityTime = activity.start_time && activity.end_time
          ? parseISO(activity.start_time)
          : parseISO(activity.created_at);
        return isWithinInterval(activityTime, {
          start: startOfDay(startDate),
          end: endOfDay(endDate)
        });
      }
      return false;
    });
  }, [activities, startDate, endDate]);

  const goalColors = useMemo(() => {
    const uniqueGoals = [...new Set(filteredActivities.map(a => a.goals.title))];
    return uniqueGoals.reduce((acc, goal, index) => {
      acc[goal] = `hsl(${index * 360 / uniqueGoals.length}, 70%, 50%)`;
      return acc;
    }, {});
  }, [filteredActivities]);

  const dateRange = useMemo(() => {
    return eachDayOfInterval({ start: startDate, end: endDate });
  }, [startDate, endDate]);

  /**
   * 活動をクリックしたときの処理
   * @param {Object} activity - クリックされた活動オブジェクト
   */
  const handleActivityClick = (activity) => {
    setSelectedReflection(activity);
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    setSelectedReflection(null);
  };

  const handleRemoveDialogOpen = () => {
    setIsEditDialogOpen(false);
    setIsRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setIsRemoveDialogOpen(false);
    setSelectedReflection(null);
  };

  const handleReflectionSave = async (updatedReflection) => {
    try {
      await handleEditReflection(updatedReflection);
      onActivityChange(); // 親コンポーネントに変更を通知
      handleEditDialogClose();
    } catch (error) {
      console.error('振り返りの更新に失敗しました:', error);
    }
  };

  const handleReflectionRemove = async (reflection) => {
    try {
      await handleDeleteReflection(reflection);
      onActivityChange(); // 親コンポーネントに変更を通知
      handleRemoveDialogClose();
    } catch (error) {
      console.error('振り返りの削除に失敗しました:', error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Paper elevation={3} sx={{ p: 3, mt: 4, overflowX: 'auto' }}>
        <Box sx={{ minWidth: '800px' }}>
          <Typography variant="h6" gutterBottom>
            期間の活動記録
          </Typography>
          <DateRangePicker
            timeRange="daily"
            startDate={format(startDate, 'yyyy-MM-dd')}
            endDate={format(endDate, 'yyyy-MM-dd')}
            onDateChange={handleDateChange}
          />
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            {dateRange.map(date => (
              <DayRow
                key={date.toISOString()}
                date={date}
                activities={filteredActivities}
                goalColors={goalColors}
                onActivityClick={handleActivityClick}
              />
            ))}
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>目標:</Typography>
            {Object.entries(goalColors).map(([goal, color]) => (
              <Box key={goal} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box sx={{ width: 20, height: 20, backgroundColor: color, mr: 1 }} />
                <Typography variant="body2">{goal}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
      {isEditDialogOpen && (
        <ReflectionDialog
          reflection={selectedReflection}
          onClose={handleEditDialogClose}
          onSave={handleReflectionSave}
          open={isEditDialogOpen}
          goal={selectedReflection.goals}
        />
      )}
      {isRemoveDialogOpen && (
        <ReflectionRemoveDialog
          reflection={selectedReflection}
          onClose={handleRemoveDialogClose}
          onRemove={handleReflectionRemove}
        />
      )}
    </LocalizationProvider>
  );
};

/**
 * 1日分の活動行を表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Date} props.date - 表示する日付
 * @param {Array} props.activities - 活動データの配列
 * @param {Object} props.goalColors - 目標ごとの色情報
 * @param {Function} props.onActivityClick - 活動クリック時のコールバック関数
 * @returns {JSX.Element} DayRowコンポーネント
 */
const DayRow = ({ date, activities, goalColors, onActivityClick }) => {
  const dayActivities = activities.filter(activity => {
    const activityTime = activity.start_time && activity.end_time
      ? parseISO(activity.start_time)
      : parseISO(activity.created_at);
    return isWithinInterval(activityTime, {
      start: startOfDay(date),
      end: endOfDay(date)
    });
  });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 60, mb: 1 }}>
      <Typography variant="body2" sx={{ width: 100, flexShrink: 0 }}>
        {format(date, 'MM/dd (E)', { locale: ja })}
      </Typography>
      <Box sx={{ flex: 1, height: '100%', position: 'relative', backgroundColor: '#f0f0f0' }}>
        {[...Array(24)].map((_, i) => (
          <Box
            key={i}
            sx={{
              position: 'absolute',
              left: `${(i / 24) * 100}%`,
              top: 0,
              height: '100%',
              borderLeft: '1px solid #ddd',
              width: `${100 / 24}%`,
            }}
          >
            <Typography variant="caption" sx={{ position: 'absolute', top: -20, left: 2 }}>
              {`${i}:00`}
            </Typography>
          </Box>
        ))}
        {dayActivities.map(activity => (
          <ActivityBar
            key={activity.id}
            activity={activity}
            date={date}
            color={goalColors[activity.goals.title]}
            onClick={onActivityClick}
          />
        ))}
      </Box>
    </Box>
  );
};

/**
 * 活動バーを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.activity - 活動データ
 * @param {Date} props.date - 表示する日付
 * @param {string} props.color - バーの色
 * @param {Function} props.onClick - クリック時のコールバック関数
 * @returns {JSX.Element} ActivityBarコンポーネント
 */
const ActivityBar = ({ activity, date, color, onClick }) => {
  const startTime = activity.start_time
    ? parseISO(activity.start_time)
    : parseISO(activity.created_at);
  const endTime = activity.end_time
    ? parseISO(activity.end_time)
    : addMinutes(parseISO(activity.created_at), 15); // 15分のデフォルト期間を設定

  const dayStart = startOfDay(date);
  const dayEnd = endOfDay(date);

  const adjustedStartTime = startTime < dayStart ? dayStart : startTime;
  const adjustedEndTime = endTime > dayEnd ? dayEnd : endTime;

  const startPercentage = (adjustedStartTime.getHours() + adjustedStartTime.getMinutes() / 60) / 24 * 100;
  const durationPercentage = (adjustedEndTime - adjustedStartTime) / (24 * 60 * 60 * 1000) * 100;

  const tooltipContent = (
    <div>
      <Typography variant="subtitle2">目標: {activity.goals.title}</Typography>
      <Typography variant="body2">
        時間: {format(adjustedStartTime, 'HH:mm')} - {format(adjustedEndTime, 'HH:mm')}
      </Typography>
      <Typography variant="body2">
        達成内容: 
        {activity.achievement ? (
          activity.achievement.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < activity.achievement.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))
        ) : '未記入'}
      </Typography>
      {!activity.start_time && !activity.end_time && (
        <Typography variant="caption">※ 時間が未設定のため、活動記録作成時刻を基準に表示</Typography>
      )}
    </div>
  );

  return (
    <Tooltip title={tooltipContent} arrow placement="top">
      <Box
        onClick={() => onClick(activity)}
        sx={{
          position: 'absolute',
          left: `${startPercentage}%`,
          width: `${durationPercentage}%`,
          height: '100%',
          backgroundColor: color,
          borderRadius: 1,
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.8,
          },
        }}
      />
    </Tooltip>
  );
};

export default DailyActivityChart;
