/**
 * @fileoverview 振り返りサービス
 * @description 振り返りの取得、作成、更新、削除を行うサービス
 */

import { supabase } from '../utils/supabase';

export const reflectionService = {
  /**
   * ユーザーの振り返りを取得する
   * @param {string} userId - ユーザーID
   * @param {string|null} goalId - 目標ID (オプション)
   * @param {string|null} projectId - プロジェクトID (オプション)
   * @returns {Promise<Array>} 振り返りの配列
   * @throws {Error} データ取得に失敗した場合
   */
  async fetchReflections(userId, goalId = null, projectId = null) {
    let query = supabase
      .from('reflections')
      .select(`
        id,
        created_at,
        goal_id,
        achievement,
        learning,
        improvement,
        skills,
        user_id,
        date,
        start_time,
        end_time,
        reflection_duration,
        reference_links,
        artifacts,
        actual_value,
        updated_at,
        goals!inner(
          id,
          title,
          unit_id,
          units:unit_id(name),
          project_id
        )
      `)
      .eq('user_id', userId);

    if (goalId) {
      query = query.eq('goal_id', goalId);
    } else if (projectId && projectId !== 'all') {
      query = query.eq('goals.project_id', projectId);
    }

    const { data, error } = await query.order('created_at', { ascending: false });

    if (error) {
      console.error('振り返りの取得に失敗しました:', error);
      throw error;
    }

    return data;
  },

  /**
   * 新しい振り返りを作成する
   * @param {Object} reflectionData - 振り返りデータ
   * @returns {Promise<Object>} 作成された振り返り
   * @throws {Error} 作成に失敗した場合
   */
  async createReflection(reflectionData) {
    const { data, error } = await supabase
      .from('reflections')
      .insert(reflectionData)
      .select();

    if (error) {
      console.error('振り返りの作成に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * 振り返りを更新する
   * @param {string} reflectionId - 振り返りID
   * @param {Object} updatedReflection - 更新する振り返りデータ
   * @returns {Promise<Object>} 更新された振り返り
   * @throws {Error} 更新に失敗した場合
   */
  async updateReflection(reflectionId, updatedReflection) {
    const { data, error } = await supabase
      .from('reflections')
      .update(updatedReflection)
      .eq('id', reflectionId)
      .select();

    if (error) {
      console.error('振り返りの更新に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * 振り返りを削除する
   * @param {string} reflectionId - 振り返りID
   * @throws {Error} 削除に失敗した場合
   */
  async deleteReflection(reflectionId) {
    const { error } = await supabase
      .from('reflections')
      .delete()
      .eq('id', reflectionId);

    if (error) {
      console.error('振り返りの削除に失敗しました:', error);
      throw error;
    }
  }
};
