/**
 * @fileoverview 日付範囲選択コンポーネント
 * @module DateRangePicker
 */

import React from 'react';
import { Box, TextField } from '@mui/material';

/**
 * 日付範囲を選択するためのコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.timeRange - 時間範囲（'monthly'または他）
 * @param {string} props.startDate - 開始日
 * @param {string} props.endDate - 終了日
 * @param {Function} props.onDateChange - 日付変更時のコールバック関数
 * @returns {React.Component} DateRangePickerコンポーネント
 */
const DateRangePicker = ({ timeRange, startDate, endDate, onDateChange }) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
      <TextField
        label="開始日"
        type={timeRange === 'monthly' ? 'month' : 'date'}
        value={startDate}
        onChange={(e) => onDateChange(e, true)}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="終了日"
        type={timeRange === 'monthly' ? 'month' : 'date'}
        value={endDate}
        onChange={(e) => onDateChange(e, false)}
        InputLabelProps={{ shrink: true }}
      />
    </Box>
  );
};

export default DateRangePicker;
