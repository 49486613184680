/**
 * @fileoverview 振り返り削除ダイアログコンポーネント
 * @module ReflectionRemoveDialog
 */

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

/**
 * 振り返り削除ダイアログを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array<Object>} props.reflections - 削除する振り返りオブジェクトの配列
 * @param {Function} props.onClose - ダイアログを閉じる関数
 * @param {Function} props.onRemove - 振り返りを削除する関数
 * @param {Function} props.onActivityChange - アクティビティ変更時のコールバック関数
 * @returns {React.ReactElement} 振り返り削除ダイアログのJSX
 */
const ReflectionRemoveDialog = ({ reflections, onClose, onRemove, onActivityChange }) => {
  /**
   * 振り返りを削除する
   */
  const handleRemove = async () => {
    try {
      await onRemove(reflections);
      onClose();
      onActivityChange(); 
    } catch (error) {
      console.error('振り返りの削除に失敗しました:', error);
      alert('振り返りの削除中にエラーが発生しました。');
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>振り返りを削除</DialogTitle>
      <DialogContent>
        <Typography>{reflections.length}件の振り返りを削除してもよろしいですか？</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleRemove} color="error">削除</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReflectionRemoveDialog;
