/**
 * @file SlidingPage.js
 * @description スライドインするページコンポーネントを実装するReactコンポーネント
 * 
 * このコンポーネントは、画面右側からスライドインするページを実装します。
 * リサイズ可能で、全画面表示の切り替えも可能です。
 * 
 * @requires React
 * @requires @mui/material
 * @requires @mui/icons-material
 */

import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { Close, OpenInFull, CloseFullscreen } from '@mui/icons-material';

/**
 * SlidingPageは、スライドインするページを表示するReactコンポーネントです。
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.isOpen - ページが開いているかどうか
 * @param {Function} props.onClose - ページを閉じる関数
 * @param {React.ReactNode} props.children - ページの内容
 * @returns {JSX.Element} スライドインページを表示するBox要素
 */
const SlidingPage = ({ isOpen, onClose, children }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [width, setWidth] = useState('50%');
  const resizeRef = useRef(null);
  const pageRef = useRef(null);

  /**
   * 全画面表示を切り替える関数
   */
  const handleToggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    setWidth(isFullScreen ? '50%' : '100%');
  };

  /**
   * リサイズを開始する関数
   * @param {React.MouseEvent} e - マウスイベント
   */
  const startResize = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', stopResize);
  };

  /**
   * リサイズを実行する関数
   * @param {MouseEvent} e - マウスイベント
   */
  const resize = (e) => {
    if (pageRef.current) {
      const newWidth = document.body.clientWidth - e.clientX;
      setWidth(`${newWidth}px`);
    }
  };

  /**
   * リサイズを停止する関数
   */
  const stopResize = () => {
    document.removeEventListener('mousemove', resize);
    document.removeEventListener('mouseup', stopResize);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', stopResize);
    };
  }, []);

  return (
    <Box
      ref={pageRef}
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        width: isFullScreen ? '100%' : width,
        backgroundColor: 'background.paper',
        boxShadow: 3,
        transition: 'transform 0.3s ease-in-out',
        transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
        zIndex: 1200,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        ref={resizeRef}
        onMouseDown={startResize}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '5px',
          cursor: 'ew-resize',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
        <IconButton onClick={handleToggleFullScreen}>
          {isFullScreen ? <CloseFullscreen /> : <OpenInFull />}
        </IconButton>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
        {children}
      </Box>
    </Box>
  );
};

export default SlidingPage;
