/**
 * @fileoverview 単位サービス
 * @description 単位の取得と作成を行うサービス
 */

import { supabase } from '../utils/supabase';

export const unitService = {
  /**
   * 単位をIDで取得する
   * @param {string} unitId - 単位ID
   * @returns {Promise<Object>} 単位データ
   * @throws {Error} データ取得に失敗した場合
   */
  async fetchUnitById(unitId) {
    const { data, error } = await supabase
      .from('units')
      .select(`
        id,
        created_at,
        user_id,
        name
      `)
      .eq('id', unitId)
      .single();

    if (error) {
      console.error('単位の取得に失敗しました:', error);
      throw error;
    }

    return data;
  },

  /**
   * 新しい単位を作成する
   * @param {string} name - 単位名
   * @param {string} userId - ユーザーID
   * @returns {Promise<Object>} 作成された単位
   * @throws {Error} 作成に失敗した場合
   */
  async createUnit(name, userId) {
    const { data, error } = await supabase
      .from('units')
      .insert({ name, user_id: userId })
      .select(`
        id,
        created_at,
        user_id,
        name
      `);

    if (error) {
      console.error('単位の作成に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * ユーザーの単位を取得する
   * @param {string} userId - ユーザーID
   * @returns {Promise<Array>} 単位の配列
   * @throws {Error} データ取得に失敗した場合
   */
  async fetchUnits(userId) {
    const { data, error } = await supabase
      .from('units')
      .select(`
        id,
        created_at,
        user_id,
        name
      `)
      .or(`user_id.is.null,user_id.eq.${userId}`)
      .order('name');

    if (error) {
      console.error('単位の取得に失敗しました:', error);
      throw error;
    }

    return data;
  }
};
