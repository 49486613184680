/**
 * @fileoverview アクティビティデータを取得・管理するカスタムフック
 * @module useActivityData
 * @requires react
 * @requires ../utils/supabase
 * @requires ../services/activityService
 */

import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { activityService } from '../services/activityService';

/**
 * アクティビティデータを取得・管理するカスタムフック
 * @function useActivityData
 * @param {string|null} [goalId=null] - 目標ID
 * @param {string|null} [projectId=null] - プロジェクトID
 * @returns {Object} アクティビティデータ、ローディング状態、エラー状態、データ取得関数を含むオブジェクト
 */
export const useActivityData = (goalId = null, projectId = null) => {
  const [userId, setUserId] = useState(null);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) setUserId(user.id);
    };
    fetchUserId();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchActivities();
    }
  }, [userId, goalId, projectId]);

  /**
   * アクティビティデータを取得する
   * @async
   * @function fetchActivities
   */
  const fetchActivities = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await activityService.fetchActivities(userId, goalId, projectId);
      setActivities(data);
    } catch (err) {
      console.error('アクティビティデータの取得に失敗しました:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    activities,
    loading,
    error,
    fetchActivities
  };
};
