/**
 * @fileoverview 目標削除確認ダイアログコンポーネント
 * 
 * このファイルは、目標を削除する前に確認を行うためのダイアログコンポーネントを定義します。
 * ユーザーに削除の確認を求め、削除操作を実行します。
 * 
 * @requires React
 * @requires @mui/material
 * @requires ../../hooks/useGoalData
 */

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useGoalData } from '../../hooks/useGoalData';

/**
 * 目標削除確認ダイアログコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.goal - 削除する目標オブジェクト
 * @param {Function} props.onClose - ダイアログを閉じる際のコールバック
 * @param {Function} props.onRemove - 目標削除後のコールバック
 * @returns {React.Component} 目標削除確認ダイアログコンポーネント
 */
const RemoveDialog = ({ goal, onClose, onRemove }) => {
  const { handleDeleteGoal } = useGoalData();

  const handleRemove = async () => {
    try {
      await handleDeleteGoal(goal);
      onRemove(); // 削除後にonRemoveを呼び出す
      onClose();
    } catch (error) {
      console.error('目標の削除に失敗しました:', error);
      alert('目標の削除中にエラーが発生しました。');
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>目標の削除</DialogTitle>
      <DialogContent>
        <Typography>
          本当にこの目標を削除しますか？ 関連する振り返りも削除されます。<br />
          この操作は取り消せません。
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleRemove} variant="contained" color="error">
          削除
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
