import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Paper, Grid, Button, IconButton, Card, CardContent, Tabs, Tab, Divider, Tooltip, Chip } from '@mui/material';
import { Add as AddIcon, ExpandMore, ChevronRight, Dashboard as DashboardIcon, Check as CheckIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGoalData } from '../hooks/useGoalData';
import { useReflectionData } from '../hooks/useReflectionData';
import { useActivityData } from '../hooks/useActivityData';
import { useProjectData } from '../hooks/useProjectData';
import GoalCard from '../components/goals/GoalCard';
import GoalDialog from '../components/goals/GoalDialog';
import RemoveDialog from '../components/goals/GoalRemoveDialog';
import CompleteDialog from '../components/goals/GoalCompleteDialog';
import RevertDialog from '../components/goals/GoalRevertDialog';
import ReflectionDialog from '../components/reflections/ReflectionDialog';
import ReflectionTimeSeriesChart from '../components/charts/ReflectionTimeSeriesChart';
import ActivityHistory from '../components/activities/ActivityHistory';
import AdComponent from '../components/common/AdComponent';
import ReflectionSummaryCard from '../components/summary/ReflectionSummaryCard';
import ReflectionSkillPieChart from '../components/charts/ReflectionSkillPieChart';
import DailyActivityChart from '../components/charts/DailyActivityChart';
import KanbanBoard from '../components/common/KanbanBoard';
import GoalTimelineChart from '../components/charts/GoalTimelineChart';
import ProjectDialog from '../components/projects/ProjectDialog';
import ProjectCard from '../components/projects/ProjectCard';
import ProjectCompleteDialog from '../components/projects/ProjectCompleteDialog';
import GoalTreeChart from '../components/charts/GoalTreeChart';
import ReflectionPage from './ReflectionPage';
import SlidingPage from '../components/common/SlidingPage';
import GoalDetailPage from './GoalDetailPage';
import RecentReflections from '../components/reflections/RecentReflections';
import GoalChatBox from '../components/goals/GoalChatBox';
import { goalService } from '../services/goalService'; // goalServiceをインポート
import GreetingBubble from '../components/common/GreetingBubble';
import { openaiService } from '../services/openaiService';
import queryString from 'query-string';

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { goal: goalId } = queryString.parse(location.search);
  const [selectedProject, setSelectedProject] = useState('all');
  const [greetingMessage, setGreetingMessage] = useState('');
  const [lastGreetingTime, setLastGreetingTime] = useState(() => {
    return localStorage.getItem('lastGreetingTime') || null;
  });

  const { 
    goals, 
    completedGoals,
    loading: goalsLoading, 
    error: goalsError, 
    fetchGoals,
    handleEditGoal,
    handleStatusChange
  } = useGoalData(selectedProject);

  const {
    reflections,
    loading: reflectionsLoading,
    error: reflectionsError,
    fetchReflections,
  } = useReflectionData(null, selectedProject);

  const {
    activities,
    loading: activitiesLoading,
    error: activitiesError,
    fetchActivities
  } = useActivityData(null, selectedProject);

  const { 
    projects,
    loading: projectsLoading,
    error: projectsError,
    handleCreateProject,
    handleUpdateProject,
    handleDeleteProject,
    handleCompleteProject,
    fetchProjects
  } = useProjectData();

  const [showGoalModal, setShowGoalModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showReflectionModal, setShowReflectionModal] = useState(false);
  const [selectedGoalForReflection, setSelectedGoalForReflection] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [showCompletedGoals, setShowCompletedGoals] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [isProjectDialogOpen, setIsProjectDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [showProjectCompleteModal, setShowProjectCompleteModal] = useState(false);
  const [selectedProjectForComplete, setSelectedProjectForComplete] = useState(null);
  const [showCompletedProjects, setShowCompletedProjects] = useState(false);
  const [isReflectionPageOpen, setIsReflectionPageOpen] = useState(false);
  const [selectedReflectionId, setSelectedReflectionId] = useState(null);
  const [selectedGoalId, setSelectedGoalId] = useState(null);
  const [isSlidingPageOpen, setIsSlidingPageOpen] = useState(false);
  const [showActiveGoals, setShowActiveGoals] = useState(true);
  const [parentGoalForNew, setParentGoalForNew] = useState(null); // 追加
  const [generatedGoalData, setGeneratedGoalData] = useState(null);
  const [subGoalsToCreate, setSubGoalsToCreate] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const [parentGoalId, setParentGoalId] = useState(null);

  // loadingの状態を統合
  const isLoading = goalsLoading || reflectionsLoading || activitiesLoading;

  // 挨拶メッセージを生成する（useEffectをトップレベルに移動）
  useEffect(() => {
    const generateGreeting = async () => {
      if (!isLoading && goals && reflections) {
        try {
          const now = new Date();
          const shouldGenerateNew = !lastGreetingTime || 
            (now - new Date(lastGreetingTime)) > (3 * 60 * 60 * 1000);

          if (shouldGenerateNew) {
            const message = await openaiService.generateDashboardGreeting({
              goals,
              reflections,
              currentTime: now.toISOString(),
            });
            setGreetingMessage(message);
            setLastGreetingTime(now.toISOString());
            localStorage.setItem('lastGreetingTime', now.toISOString());
            localStorage.setItem('lastGreetingMessage', message);
          } else {
            const savedMessage = localStorage.getItem('lastGreetingMessage');
            if (savedMessage) {
              setGreetingMessage(savedMessage);
            }
          }
        } catch (error) {
          console.error('挨拶の生成に失敗しました:', error);
        }
      }
    };

    generateGreeting();
  }, [isLoading, goals, reflections, lastGreetingTime]);

  const handleGoalSettingClick = () => {
    setSelectedGoal(null);
    setShowGoalModal(true);
  };

  const handleReflectionClick = (goal) => {
    setSelectedGoalForReflection(goal);
    setSelectedReflectionId(null); // 新しい振り返りを作成する場合はnullに設定
    setIsReflectionPageOpen(true);
  };

  const handleEditClick = (goal) => {
    setSelectedGoal(goal);
    setShowGoalModal(true);
  };

  const handleRemoveClick = (goal) => {
    setSelectedGoal(goal);
    setShowRemoveModal(true);
  };

  const handleCompleteClick = (goal) => {
    setSelectedGoal(goal);
    setShowCompleteModal(true);
  };

  const handleRevertClick = (goal) => {
    setSelectedGoal(goal);
    setShowRevertModal(true);
  };

  const handleRemoveGoal = () => {
    fetchGoals();
    fetchActivities();
    setShowRemoveModal(false);
  };

  const handleActivityChange = useCallback(async () => {
    await fetchReflections();
    await fetchGoals();
    await fetchActivities();
  }, [fetchReflections, fetchGoals, fetchActivities]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { draggableId, destination } = result;
    const goalId = parseInt(draggableId);
    const newStatus = destination.droppableId;

    const updatedGoal = goals.find((goal) => goal.id === goalId);
    if (updatedGoal) {
      const updatedGoalWithStatus = { ...updatedGoal, status: newStatus === 'not_started' ? null : newStatus };
      handleEditGoal(updatedGoalWithStatus);
    }
  };

  // 未完了の目標をフィルタリング
  const activeGoals = goals.filter(goal => !goal.is_completed);

  const handleReflectionChange = useCallback(async () => {
    await fetchReflections();
    await fetchGoals();
  }, [fetchReflections, fetchGoals]);

  const handleProjectChange = (event, newValue) => {
    setSelectedProject(newValue);
  };

  const handleAddProject = () => {
    setShowProjectModal(true);
  };

  const renderProjectCard = () => {
    if (selectedProject === 'all') return null;
    const project = projects.find(p => p.id === selectedProject);
    if (!project) return null;

    return (
      <ProjectCard
        project={project}
        onEditClick={handleEditProject}
        onRemoveClick={handleRemoveProject}
        onCompleteClick={handleCompleteProjectClick} // この行を変更
        onRevertClick={handleRevertProject}
      />
    );
  };

  if (isLoading) {
    return <Typography>読み込み中...</Typography>;
  }

  if (goalsError || reflectionsError || activitiesError) {
    return <Typography color="error">エラー: {goalsError || reflectionsError || activitiesError}</Typography>;
  }

  const handleEditGoalFromTimeline = (goal) => {
    setSelectedGoal(goal);
    setShowGoalModal(true);
  };

  const handleGoalClick = (goalId) => {
    navigate(`${location.pathname}?goal=${goalId}`);
  };

  const handleReflectionClickFromTimeline = (reflection) => {
    setSelectedGoalForReflection(goals.find(goal => goal.id === reflection.goal_id));
    setShowReflectionModal(true);
  };

  const handleEditProject = (project) => {
    setEditingProject(project);
    setIsProjectDialogOpen(true);
  };

  const handleCloseProjectDialog = () => {
    setEditingProject(null);
    setIsProjectDialogOpen(false);
  };

  const handleSaveProject = async (projectData) => {
    try {
      if (editingProject) {
        await handleUpdateProject(editingProject.id, {
          ...editingProject,
          ...projectData
        });
      } else {
        await handleCreateProject(projectData);
      }
      handleCloseProjectDialog();
    } catch (error) {
      console.error('プロジェクトの保存に失敗しました:', error);
      setError(error.message);
    }
  };

  const handleRemoveProject = (project) => {
    handleDeleteProject(project.id);
  };

  const handleProjectCompleteConfirm = async (projectId, completeDate) => {
    try {
      await handleCompleteProject(projectId, completeDate);
      setShowProjectCompleteModal(false);
      // プロジェクトリストを更新
      await fetchProjects();
    } catch (error) {
      console.error('プロジェクトの完了に失敗しました:', error);
      setError(error.message);
    }
  };

  const handleCompleteProjectClick = (project) => {
    setSelectedProjectForComplete(project);
    setShowProjectCompleteModal(true);
  };

  const handleRevertProject = (project) => {
    handleUpdateProject(project.id, { ...project, is_completed: false, complete_date: null });
  };

  const activeProjects = projects.filter(project => !project.is_completed);
  const completedProjects = projects.filter(project => project.is_completed);

  const handleCloseReflectionPage = () => {
    setIsReflectionPageOpen(false);
    setSelectedGoalForReflection(null);
    setSelectedReflectionId(null);
    handleActivityChange();
  };

  const handleCloseSlidingPage = () => {
    navigate(location.pathname);
  };

  const handleAddChildGoal = (goalData) => {
    setSelectedGoal(null);
    setParentGoalForNew(goalData.parentGoal);
    setProjectId(goalData.project_id);
    setParentGoalId(goalData.parent_goal_id);
    setShowGoalModal(true);
  };

  // 更新処理を行う関数を追加
  const handleRefresh = async () => {
    await Promise.all([
      fetchGoals(),
      fetchReflections(),
      fetchActivities(),
      fetchProjects()
    ]);
  };

  // 目標生成ダイアログを表示する関数を修正
  const handleShowGeneratedGoalDialog = (goalData) => {
    if (!goalData) return;
    
    setGeneratedGoalData(goalData);
    setSubGoalsToCreate(goalData.subGoals || []);
    setShowGoalModal(true);
  };

  // 目標保存時の処理を修正
  const handleSaveGeneratedGoal = async (mainGoal) => {
    try {
      // メイン目標とサブ目標を一括で保存
      const result = await goalService.createGoalWithSubGoals(mainGoal, subGoalsToCreate);
      
      // 状態をリセット
      setGeneratedGoalData(null);
      setSubGoalsToCreate([]);
      setShowGoalModal(false);
      
      // データを再取得
      fetchGoals();
      fetchActivities();
    } catch (error) {
      console.error('目標の保存に失敗しました:', error);
      setError('目標の保存に失敗しました');
    }
  };

  // 通常の目標保存ハンドラ
  const handleSaveGoal = async (goalData) => {
    try {
      let result;
      if (goalData.id) {
        result = await goalService.updateGoal(goalData.id, goalData);
      } else {
        result = await goalService.createGoal(goalData);
      }
      fetchGoals();
      return result;
    } catch (error) {
      console.error('目標の保存に失敗しました:', error);
      setError('目標の保存に失敗しました');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)', overflowY: 'auto', bgcolor: '#FAFAFA' }}>
        <Paper elevation={0} sx={{ borderRadius: 0, mb: 3 }}>
          <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
            <DashboardIcon sx={{ mr: 2, color: 'primary.main' }} />
            <Typography variant="h5" component="h1" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              ダッシュボード
            </Typography>
            <Tooltip title="更新">
              <IconButton onClick={handleRefresh} sx={{ ml: 1 }}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tabs
              value={selectedProject}
              onChange={handleProjectChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="プロジェクトタブ"
              sx={{ flexGrow: 1 }}
            >
              <Tab
                label="すべて"
                value="all"
                sx={{
                  textTransform: 'none',
                  minWidth: 0,
                  padding: '12px 16px',
                  color: 'text.secondary',
                  '&.Mui-selected': {
                    color: 'primary.main',
                  },
                }}
              />
              {activeProjects.map((project) => (
                <Tab
                  key={project.id}
                  label={project.name}
                  value={project.id}
                  sx={{
                    textTransform: 'none',
                    minWidth: 0,
                    padding: '12px 16px',
                    color: 'text.secondary',
                    '&.Mui-selected': {
                      color: 'primary.main',
                    },
                  }}
                />
              ))}
            </Tabs>
            <Tooltip title="新しいプロジェクト">
              <IconButton onClick={handleAddProject} sx={{ mr: 1 }}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={showCompletedProjects ? "完了したプロジェクを隠す" : "完了したプロジェクトを表示"}>
              <IconButton onClick={() => setShowCompletedProjects(!showCompletedProjects)}>
                {showCompletedProjects ? <ExpandMore /> : <ChevronRight />}
              </IconButton>
            </Tooltip>
          </Box>
          {showCompletedProjects && (
            <Box sx={{ mt: 2, px: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                完了したプロジェク:
              </Typography>
              {completedProjects.map((project) => (
                <Chip
                  key={project.id}
                  label={project.name}
                  onClick={() => handleProjectChange(null, project.id)}
                  onDelete={() => handleRevertProject(project)}
                  deleteIcon={<CheckIcon />}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>
          )}
        </Paper>

        {/* 挨拶メッセージを追加 */}
        {greetingMessage && (
          <GreetingBubble message={greetingMessage} />
        )}

        <Box sx={{ px: 3, pb: 3 }}>
          {/* 目標設定ボタン */}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleGoalSettingClick}
            sx={{ 
              width: '100%', 
              mb: 4, 
              py: 1.5,
              bgcolor: '#FFA500',
              '&:hover': { bgcolor: '#FF8C00' },
              color: 'white',
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            新しい目標を設定する
          </Button>

          {/* チャットボックスの実装を修正 */}
          <Box sx={{ mb: 4 }}>
            <GoalChatBox 
              onShowGoalDialog={handleShowGeneratedGoalDialog}
              projectId={selectedProject === 'all' ? null : selectedProject}
            />
          </Box>

          {/* プロジェクトカード */}
          {renderProjectCard()}

          {/* 目標ツリーチャートを追加 */}
          <Box sx={{ mt: 4, overflowX: 'auto' }}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <GoalTreeChart 
                goals={selectedProject === 'all' ? goals : goals.filter(goal => goal.project_id === selectedProject)} 
                projects={selectedProject === 'all' ? projects : projects.filter(project => project.id === selectedProject)}
                completedGoals={completedGoals}
                onAddChildGoal={handleAddChildGoal}
              />
            </Paper>
          </Box>

          {/* 直近の振り返りを追加 */}
          <Box sx={{ mt: 4 }}>
            <RecentReflections 
              defaultProjectFilter={selectedProject === 'all' ? '' : selectedProject}
              refreshTrigger={reflections}
            />
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 2 }}>
            <IconButton 
              onClick={() => setShowActiveGoals(!showActiveGoals)}
              size="small"
              sx={{ mr: 1 }}
            >
              {showActiveGoals ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              目標一覧 ({activeGoals.length}件)
            </Typography>
          </Box>
          {showActiveGoals && (
            <KanbanBoard
              items={activeGoals}
              onDragEnd={onDragEnd}
              itemComponent={({ item }) => (
                <GoalCard
                  goal={item}
                  isCompleted={item.status === 'completed'}
                  onReflectionClick={() => handleReflectionClick(item)}
                  onEditClick={() => handleEditClick(item)}
                  onRemoveClick={() => handleRemoveClick(item)}
                  onCompleteClick={() => handleCompleteClick(item)}
                  onRevertClick={() => handleRevertClick(item)}
                  onStatusChange={handleStatusChange}
                  showFullDetails={true}
                  parentGoalTitle={item.parent_goal_title}
                  childGoals={goals.filter(g => g.parent_goal_id === item.id)}
                />
              )}
            />
          )}
          
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 2 }}>
            <IconButton 
              onClick={() => setShowCompletedGoals(!showCompletedGoals)}
              size="small"
              sx={{ mr: 1 }}
            >
              {showCompletedGoals ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              完了した目標 ({completedGoals.length}件)
            </Typography>
          </Box>
          {showCompletedGoals && (
            <Grid container spacing={2}>
              {completedGoals.map((goal) => (
                <Grid item xs={12} sm={6} md={4} key={goal.id}>
                  <GoalCard
                    goal={goal}
                    isCompleted={true}
                    onReflectionClick={handleReflectionClick}
                    onEditClick={handleEditClick}
                    onRemoveClick={handleRemoveClick}
                    onRevertClick={handleRevertClick}
                    onNavigate={(id) => handleGoalClick(id)}
                    showFullDetails={true}
                    parentGoalTitle={goal.parent_goal_title}
                    childGoals={goals.filter(g => g.parent_goal_id === goal.id)}
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {/* <Box sx={{ my: 4 }}>
            <AdComponent />
          </Box> */}

          <Box sx={{ my: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 3, height: '100%', minHeight: '400px' }}>
                  <ReflectionSummaryCard 
                    showDetails={false} 
                    projectID={selectedProject === 'all' ? null : selectedProject}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 3, height: '100%', minHeight: '400px' }}>
                  <ReflectionSkillPieChart projectID={selectedProject === 'all' ? null : selectedProject} />
                </Paper>
              </Grid>
            </Grid>
          </Box>

          {/* 目標タイムラインを Paper で囲む */}
          <Box sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <GoalTimelineChart 
                goals={activeGoals} 
                completedGoals={completedGoals} // この行を追加
                reflections={reflections}
                showCard={false} 
                onEditGoal={handleEditGoalFromTimeline}
                onGoalClick={handleGoalClick}
                onReflectionClick={handleReflectionClickFromTimeline}
                onReflectionChange={handleActivityChange}
              />
            </Paper>
          </Box>

          <DailyActivityChart 
            activities={activities} 
            goals={goals}
            onActivityChange={handleActivityChange} 
            selectedProject={selectedProject === 'all' ? null : selectedProject}
          />
          
          <Box sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <ReflectionTimeSeriesChart 
                goals={activeGoals} 
                reflections={reflections}
                showCard={false} 
                onEditGoal={handleEditGoalFromTimeline}
                onGoalClick={handleGoalClick}
                onReflectionClick={handleReflectionClickFromTimeline}
                onReflectionChange={handleActivityChange}
                projectID={selectedProject === 'all' ? null : selectedProject}
              />
            </Paper>
          </Box>
          
          {/* <Box sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 3, maxHeight: '75vh', overflowY: 'auto' }}>
              <ActivityHistory 
                activities={activities} 
                onEditGoal={handleEditGoal}
                onDeleteGoal={handleDeleteGoal}
                onEditReflection={handleEditReflection}
                onDeleteReflection={handleDeleteReflection}
                onActivityChange={handleActivityChange} 
              />
            </Paper>
          </Box> */}

        </Box>
        <SlidingPage 
          isOpen={Boolean(goalId)} 
          onClose={handleCloseSlidingPage}
        >
          {goalId && (
            <GoalDetailPage
              id={parseInt(goalId)}
              onClose={handleCloseSlidingPage}
            />
          )}
        </SlidingPage>
      </Box>
      {showReflectionModal && (
        <ReflectionDialog
          goal={selectedGoalForReflection}
          onClose={() => setShowReflectionModal(false)}
          onSave={() => {
            setShowReflectionModal(false);
            handleReflectionChange();  // 変更後に画面を更新
          }}
          open={showReflectionModal}
        />
      )}
      {showGoalModal && (
        <GoalDialog
          goal={selectedGoal}
          initialData={generatedGoalData}
          subGoals={subGoalsToCreate}
          onClose={() => {
            setShowGoalModal(false);
            setSelectedGoal(null);
            setGeneratedGoalData(null);
            setSubGoalsToCreate([]);
            setParentGoalForNew(null);
            setProjectId(null);
            setParentGoalId(null);
          }}
          onSave={generatedGoalData ? handleSaveGeneratedGoal : handleSaveGoal}
          availableParentGoals={goals.filter(g => g.id !== selectedGoal?.id)}
          parentGoal={parentGoalForNew}
          defaultProjectId={projectId}
          defaultParentGoalId={parentGoalId}
        />
      )}
      {showRemoveModal && (
        <RemoveDialog
          goal={selectedGoal}
          onClose={() => setShowRemoveModal(false)}
          onRemove={handleRemoveGoal}
          onActivityChange={handleActivityChange} 
        />
      )}
      {showCompleteModal && (
        <CompleteDialog
          goal={selectedGoal}
          onClose={() => setShowCompleteModal(false)}
          onUpdate={() => {
            fetchGoals();
            fetchActivities();
          }}
        />
      )}
      {showRevertModal && (
        <RevertDialog
          goal={selectedGoal}
          onClose={() => setShowRevertModal(false)}
          onUpdate={() => {
            fetchGoals(); 
            fetchActivities(); 
          }}
        />
      )}
      {showProjectModal && (
        <ProjectDialog
          onClose={() => setShowProjectModal(false)}
          onSave={(projectData) => {
            handleCreateProject(projectData);
            setShowProjectModal(false);
          }}
        />
      )}
      {isProjectDialogOpen && (
        <ProjectDialog
          project={editingProject}
          onClose={handleCloseProjectDialog}
          onSave={handleSaveProject}
        />
      )}
      {showProjectCompleteModal && (
        <ProjectCompleteDialog
          project={selectedProjectForComplete}
          onClose={() => setShowProjectCompleteModal(false)}
          onComplete={handleProjectCompleteConfirm}
        />
      )}
      <ReflectionPage
        isOpen={isReflectionPageOpen}
        onClose={handleCloseReflectionPage}
        goalId={selectedGoalForReflection?.id}
        reflectionId={selectedReflectionId}
      />
    </LocalizationProvider>
  );
};

export default Dashboard;
