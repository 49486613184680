import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReflectionForm from '../components/reflections/ReflectionForm';
import { useGoalData } from '../hooks/useGoalData';
import { useReflectionData } from '../hooks/useReflectionData';
import SlidingPage from '../components/common/SlidingPage';

const ReflectionPage = ({ 
  isOpen, 
  onClose, 
  goalId, 
  reflectionId,
  defaultAchievement = '' // 新しいプロップを追加
}) => {
  const navigate = useNavigate();
  const { goals } = useGoalData();
  const { reflections, handleEditReflection } = useReflectionData(goalId);

  const goal = goals.find(g => g.id === parseInt(goalId));
  const reflection = reflectionId ? reflections.find(r => r.id === parseInt(reflectionId)) : null;

  const handleSave = async (reflectionData) => {
    await handleEditReflection(reflectionData);
    onClose();
  };

  if (!goal) return null;

  return (
    <SlidingPage isOpen={isOpen} onClose={onClose}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <ReflectionForm
            goal={goal}
            reflection={reflection}
            onSave={handleSave}
            onClose={onClose}
            isDialog={false}
            defaultAchievement={defaultAchievement} // 新しいプロップを渡す
          />
        </Container>
      </LocalizationProvider>
    </SlidingPage>
  );
};

export default ReflectionPage;
