/**
 * @fileoverview チャート関連のユーティリティ関数を提供するモジュール
 * @module chartUtils
 */

import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';

/**
 * 日付を指定された範囲に基づいてフォーマットする
 * @param {Date} date - フォーマットする日付
 * @param {string} range - 日付の範囲（'monthly'または他）
 * @returns {string} フォーマットされた日付文字列
 */
export const formatDate = (date, range) => {
    if (range === 'monthly') {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
    }
    return date.toISOString().split('T')[0];
  };
  
  /**
   * 日付と範囲に基づいてキーを生成する
   * @param {Date} date - キーを生成する日付
   * @param {string} range - 日付の範囲（'daily', 'weekly', 'monthly'）
   * @returns {string} 生成されたキー
   */
  export const getKey = (date, range) => {
    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Invalid date:', date);
      return '';
    }
    switch (range) {
      case 'daily':
        return date.toISOString().split('T')[0];
      case 'weekly':
        return `${date.getFullYear()}-W${getWeekNumber(date)}`;
      case 'monthly':
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      default:
        return date.toISOString().split('T')[0];
    }
  };
  
  /**
   * 指定された日付の週番号を取得する
   * @param {Date} date - 週番号を取得する日付
   * @returns {number} 週番号
   */
  export const getWeekNumber = (date) => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
  };
  
  /**
   * X軸のラベルをフォーマットする
   * @param {string} date - フォーマットする日付文字列
   * @param {string} timeRange - 時間範囲（'weekly', 'monthly', 'daily'）
   * @returns {string} フォーマットされたX軸ラベル
   */
  export const formatXAxisLabel = (date, timeRange) => {
    if (!date) return '';

    try {
      let dateObj;
      if (timeRange === 'weekly') {
        const [year, week] = date.split('-W');
        return `${year}年第${week}週`;
      } else if (timeRange === 'monthly') {
        const [year, month] = date.split('-');
        return `${year}年${month}月`;
      } else {
        // daily
        dateObj = parse(date, 'yyyy-MM-dd', new Date());
        return format(dateObj, 'MM/dd (E)', { locale: ja });
      }
    } catch (error) {
      console.error('日付のフォーマットエラー:', error, 'date:', date, 'timeRange:', timeRange);
      return '';
    }
  };
  
  /**
   * 指定された日付と範囲に基づいて次の日付を取得する
   * @param {Date} date - 基準となる日付
   * @param {string} range - 日付の範囲（'daily', 'weekly', 'monthly'）
   * @returns {Date} 次の日付
   */
  export const getNextDate = (date, range) => {
    const newDate = new Date(date);
    switch (range) {
      case 'daily':
        newDate.setDate(newDate.getDate() + 1);
        break;
      case 'weekly':
        newDate.setDate(newDate.getDate() + 7);
        break;
      case 'monthly':
        newDate.setMonth(newDate.getMonth() + 1);
        break;
      default:
        newDate.setDate(newDate.getDate() + 1);
    }
    return newDate;
  };
  
  /**
   * 指定された時間範囲のデフォルトの日付範囲を取得する
   * @param {string} timeRange - 時間範囲（'daily', 'weekly', 'monthly'）
   * @param {Object} [customRanges={}] - カスタム範囲の設定
   * @returns {Object} 開始日と終了日を含むオブジェクト
   */
  export const getDefaultDateRange = (timeRange, customRanges = {}) => {
    const end = new Date();
    let start = new Date(end);

    const defaultRanges = {
      daily: 6,
      weekly: 4,
      monthly: 2
    };

    const ranges = { ...defaultRanges, ...customRanges };

    switch (timeRange) {
      case 'daily':
        start.setDate(end.getDate() - ranges.daily);
        break;
      case 'weekly':
        start.setDate(end.getDate() - (ranges.weekly * 7));
        break;
      case 'monthly':
        start.setMonth(end.getMonth() - ranges.monthly);
        break;
      default:
        start.setDate(end.getDate() - ranges.daily);
    }

    return {
      startDate: formatDate(start, timeRange),
      endDate: formatDate(end, timeRange)
    };
  };

  /**
   * 指定された時間範囲の対称的な日付範囲を取得する
   * @param {string} timeRange - 時間範囲（'daily', 'weekly', 'monthly'）
   * @returns {Object} 開始日と終了日を含むオブジェクト
   */
  export const getSymmetricalDateRange = (timeRange) => {
    const today = new Date();
    let start = new Date(today);
    let end = new Date(today);

    switch (timeRange) {
      case 'daily':
        start.setDate(today.getDate() - 5);
        end.setDate(today.getDate() + 5);
        break;
      case 'weekly':
        start.setDate(today.getDate() - 14);
        end.setDate(today.getDate() + 14);
        break;
      case 'monthly':
        start.setMonth(today.getMonth() - 1);
        end.setMonth(today.getMonth() + 1);
        break;
      default:
        start.setDate(today.getDate() - 7);
        end.setDate(today.getDate() + 7);
    }

    return {
      startDate: formatDate(start, timeRange),
      endDate: formatDate(end, timeRange)
    };
  };
