import React, { useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Typography, Box, Tooltip, FormControlLabel, Switch, IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import GoalTooltip from '../common/GoalTooltip';
import GoalProgress from '../common/GoalProgress';
import { formatNumber } from '../../utils/commonUtils';
import SlidingPage from '../common/SlidingPage';
import GoalDetailPage from '../../pages/GoalDetailPage';
import AddIcon from '@mui/icons-material/Add';

/**
 * プロジェクトと目標のツリーチャートを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.goals - 目標の配列
 * @param {Array} props.projects - プロジェクトの配列
 * @param {Array} props.completedGoals - 完了した目標の配列
 * @param {Function} props.onAddChildGoal - 子目標追加処理
 * @returns {JSX.Element} GoalTreeChartコンポーネント
 */
const GoalTreeChart = ({ goals, projects, completedGoals, onAddChildGoal }) => {
  const [showCompletedGoals, setShowCompletedGoals] = useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState(null);
  const [isSlidingPageOpen, setIsSlidingPageOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * 目標の状態に応じた背景色を取得する
   * @param {string} status - 目標の状態
   * @param {boolean} isCompleted - 目標が完了しているかどうか
   * @returns {string} 背景色
   */
  const getBackgroundColor = (status, isCompleted) => {
    if (isCompleted) {
      return '#ffcccb';
    }
    switch (status) {
      case 'not_started':
        return '#f0f0f0';
      case 'in_progress':
        return '#e3f2fd';
      case 'completed':
        return '#e8f5e9';
      default:
        return '#ffffff';
    }
  };

  /**
   * 目標をクリックしたときの処理
   * @param {number} goalId - 目標のID
   */
  const handleGoalClick = (goalId) => {
    navigate(`${location.pathname}?goal=${goalId}`);
  };

  /**
   * サイドピークを閉じる処理
   */
  const handleCloseSlidingPage = () => {
    setIsSlidingPageOpen(false);
    setSelectedGoalId(null);
  };

  /**
   * 目標を期限でソートする関数
   * @param {Object} a - 目標オブジェクト
   * @param {Object} b - 目標オブジェクト
   * @returns {number} ソート順
   */
  const sortGoalsByDeadline = (a, b) => {
    if (a.deadline === null && b.deadline === null) return 0;
    if (a.deadline === null) return 1;
    if (b.deadline === null) return -1;
    return new Date(a.deadline) - new Date(b.deadline);
  };

  /**
   * 目標ノードを再帰的にレンダリングする
   * @param {Object} goal - 目標オブジェクト
   * @param {boolean} isCompleted - 目標が完了しているかどうか
   * @returns {JSX.Element} 目標ノード
   */
  const renderGoalNode = (goal, isCompleted = false) => {
    const childGoals = goals.filter(g => g.parent_goal_id === goal.id);
    const completedChildGoals = completedGoals.filter(g => g.parent_goal_id === goal.id);
    const allChildGoals = showCompletedGoals ? [...childGoals, ...completedChildGoals] : childGoals;
    const backgroundColor = getBackgroundColor(goal.status, isCompleted);

    allChildGoals.sort(sortGoalsByDeadline);

    return (
      <TreeNode
        key={goal.id}
        label={
          <Box sx={{ 
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Tooltip
              title={<GoalTooltip goal={goal} />}
              arrow
              placement="top"
            >
              <Box
                onClick={() => handleGoalClick(goal.id)}
                sx={{
                  p: 1,
                  border: '1px solid #ccc',
                  borderRadius: 1,
                  backgroundColor: backgroundColor,
                  width: '200px',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: '0 0 5px rgba(0,0,0,0.2)',
                    '& .add-child-button': {
                      opacity: 1,
                    },
                  },
                  position: 'relative',
                }}
              >
                <Typography variant="body2" noWrap>
                  {goal.title}
                </Typography>
                <Typography variant="caption" display="block" noWrap>
                  期限: {goal.deadline ? new Date(goal.deadline).toLocaleDateString() : '未設定'}
                </Typography>
                <Typography variant="caption" display="block" noWrap>
                  目標値: {goal.target_value !== null ? `${formatNumber(parseFloat(goal.target_value))}${goal.unit_name || ''}` : '未設定'}
                </Typography>
                {shouldShowProgress(goal) && (
                  <GoalProgress goal={goal} height={4} showPercentage={true} />
                )}
                <IconButton
                  className="add-child-button"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAddChildGoal({
                      parent_goal_id: goal.id,
                      project_id: goal.project_id,
                      parentGoal: goal
                    });
                  }}
                  sx={{
                    position: 'absolute',
                    bottom: -20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    opacity: 0,
                    transition: 'opacity 0.2s',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                  }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        }
      >
        {allChildGoals.map(childGoal => 
          renderGoalNode(childGoal, completedChildGoals.some(cg => cg.id === childGoal.id))
        )}
      </TreeNode>
    );
  };

  /**
   * 進捗バーを表示すべきかどうかを判断する
   * @param {Object} goal - 目標オブジェクト
   * @returns {boolean} 進捗バーを表示すべきかどうか
   */
  const shouldShowProgress = (goal) => {
    return goal.target_value && parseFloat(goal.target_value) > 0;
  };

  /**
   * プロジェクトノードをレンダリングする
   * @param {Object} project - プロジェクトオブジェクト
   * @returns {JSX.Element} プロジェクトノード
   */
  const renderProjectNode = (project) => {
    const projectGoals = goals.filter(goal => goal.project_id === project.id && !goal.parent_goal_id);
    const completedProjectGoals = completedGoals.filter(goal => goal.project_id === project.id && !goal.parent_goal_id);
    const allProjectGoals = showCompletedGoals ? [...projectGoals, ...completedProjectGoals] : projectGoals;

    allProjectGoals.sort(sortGoalsByDeadline);

    return (
      <TreeNode
        key={project.id}
        label={
          <Box sx={{ 
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Box
              sx={{
                p: 1,
                border: '2px solid #3f51b5',
                borderRadius: 2,
                backgroundColor: '#e8eaf6',
                width: '250px',
                position: 'relative',
                '&:hover': {
                  '& .add-child-button': {
                    opacity: 1,
                  },
                },
              }}
            >
              <Typography variant="subtitle1" noWrap>
                {project.name}
              </Typography>
              <IconButton
                className="add-child-button"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddChildGoal({
                    project_id: project.id,
                    parent_goal_id: null,
                    parentGoal: null
                  });
                }}
                sx={{
                  position: 'absolute',
                  bottom: -20,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        }
      >
        {allProjectGoals.map(goal => 
          renderGoalNode(goal, completedProjectGoals.some(cg => cg.id === goal.id))
        )}
      </TreeNode>
    );
  };

  /**
   * プロジェクト無しの目標ノードをレンダリングする
   * @returns {JSX.Element} プロジェクト無しノード
   */
  const renderNoProjectNode = () => {
    const noProjectGoals = goals.filter(goal => !goal.project_id && !goal.parent_goal_id);
    const completedNoProjectGoals = completedGoals.filter(goal => !goal.project_id && !goal.parent_goal_id);
    const allNoProjectGoals = showCompletedGoals ? [...noProjectGoals, ...completedNoProjectGoals] : noProjectGoals;
    
    allNoProjectGoals.sort(sortGoalsByDeadline);

    if (allNoProjectGoals.length === 0) {
      return null;
    }

    return (
      <TreeNode
        label={
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                p: 1,
                border: '2px solid #9e9e9e',
                borderRadius: 2,
                backgroundColor: '#f5f5f5',
                width: '250px',
                position: 'relative',
                left: allNoProjectGoals.length > 0 ? '50%' : '0',
                transform: allNoProjectGoals.length > 0 ? 'translateX(-50%)' : 'none',
                '&:hover': {
                  '& .add-child-button': {
                    opacity: 1,
                  },
                },
              }}
            >
              <Typography variant="subtitle1" noWrap>
                プロジェクト無し
              </Typography>
              <IconButton
                className="add-child-button"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddChildGoal({});
                }}
                sx={{
                  position: 'absolute',
                  bottom: -20,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        }
      >
        {allNoProjectGoals.map(goal => 
          renderGoalNode(goal, completedNoProjectGoals.some(cg => cg.id === goal.id))
        )}
      </TreeNode>
    );
  };

  // 完了していないプロジェクトのみをフィルタリング
  const activeProjects = projects.filter(project => !project.is_completed);

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <FormControlLabel
        control={
          <Switch
            checked={showCompletedGoals}
            onChange={(e) => setShowCompletedGoals(e.target.checked)}
            name="showCompletedGoals"
          />
        }
        label="完了した目標を表示"
      />
      <Box sx={{ minWidth: '800px', p: 2 }}>
        <Tree
          lineWidth={'2px'}
          lineColor={'#bbb'}
          lineBorderRadius={'10px'}
          label={<Typography variant="h6">プロジェクトと目標のツリー</Typography>}
        >
          {activeProjects.map(project => renderProjectNode(project))}
          {renderNoProjectNode()}
        </Tree>
      </Box>
      <SlidingPage isOpen={isSlidingPageOpen} onClose={handleCloseSlidingPage}>
        {selectedGoalId && (
          <GoalDetailPage
            id={selectedGoalId}
            onClose={handleCloseSlidingPage}
          />
        )}
      </SlidingPage>
    </Box>
  );
};

export default GoalTreeChart;
