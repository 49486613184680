/**
 * @file PrivateHeader.js
 * @description ログイン後のヘッダーを表示するReactコンポーネント
 * 
 * このコンポーネントは、ログイン後のヘッダーバーを実装します。
 * メニュー、通知、ユーザーアカウントへのアクセスを提供します。
 * 
 * @requires React
 * @requires react-router-dom
 * @requires @mui/material
 * @requires @mui/icons-material
 */

import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';

/**
 * PrivateHeaderは、ログイン後のヘッダーバーを表示するReactコンポーネントです。
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Function} props.toggleMenu - サイドメニューの開閉を切り替える関数
 * @param {Function} props.onLogout - ログアウト処理を行う関数
 * @returns {JSX.Element} ヘッダーバーを表示するAppBar要素
 */
const PrivateHeader = ({ toggleMenu, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    onLogout();
  };

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        background: 'linear-gradient(45deg, #0131b4 0%, #0142b4 50%, #0175b4 100%)',
        boxShadow: '0 3px 5px 2px rgba(1, 49, 180, .3)'
      }}
      >  
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleMenu}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/" style={{ flexGrow: 1, textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
          <img 
            src="/images/logo.png" 
            alt="RefReco Logo" 
            style={{ height: '40px', maxWidth: '200px', objectFit: 'contain', marginTop: '4px' }} 
          />
        </Link>
        <IconButton color="inherit" aria-label="notifications">
          <NotificationsIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default PrivateHeader;
