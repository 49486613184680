import React, { useEffect } from 'react';
import { Container, Box, Typography, Button, IconButton, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import XIcon from '@mui/icons-material/X';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleStartClick = () => {
    // ここでユーザーの認証状態を確認する
    const isLoggedIn = false; // 仮の値。実際の認証状態に応じて変更する

    if (isLoggedIn) {
      navigate('/dashboard'); // ログイン済みの場合、ダッシュボードへ
    } else {
      navigate('/register'); // 未ログインの場合、登録ページへ
    }
  };

  useEffect(() => {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "RefReco",
      "description": "目標達成と成長を加速させる振り返りツール",
      "url": "https://www.refreco.jp/"
    };

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* ヒーローセクション */}
      <Box sx={{ 
        background: 'linear-gradient(135deg, #0131b4 0%, #1e90ff 100%)',
        color: 'white',
        py: 12,
        mt: { xs: 13.5, sm: 8 }, 
        paddingTop: { xs: '64px', sm: '72px' } 
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h2" component="h1" gutterBottom>
                  RefReco - リフレコ - 
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  目標達成と成長を加速させる振り返りツール
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  RefRecoは、目標設定とリフレクション（振り返り）を融合させた革新的な学習・成長支援ツールです。<br />
                  OKRの考え方を取り入れ、あなたの学習効果を最大化します。
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100%'
              }}>
                <img 
                  src="/images/refrecoscreenshot.png" 
                  alt="RefReco操作画面" 
                  style={{ 
                    maxWidth: '100%', 
                    height: 'auto',
                    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
                  }} 
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
            <Button 
              variant="contained" 
              size="large"
              onClick={handleStartClick}
              sx={{ 
                bgcolor: 'white', 
                color: '#0131b4',
                fontSize: '1.2rem',
                padding: '12px 24px',
                '&:hover': {
                  bgcolor: '#f0f8ff',
                }
              }}>
              無料で始める
            </Button>
          </Box>
        </Container>
      </Box>

      {/* サービスの特徴セクション */}
      <Box sx={{ bgcolor: '#ffffff', py: 10 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" gutterBottom>
            RefRecoの主な特徴
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ 
                p: 4, 
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                }
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
                  <TrackChangesIcon sx={{ fontSize: 80, color: '#0131b4' }} />
                </Box>
                <Typography variant="h6" gutterBottom>
                  目標と振り返りの一元管理
                </Typography>
                <Typography>
                  目標設定からリフレクションまで、一つのツールで簡単に管理できます。
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ 
                p: 4, 
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                }
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
                  <TimelineIcon sx={{ fontSize: 80, color: '#0131b4' }} />
                </Box>
                <Typography variant="h6" gutterBottom>
                  進捗の可視化
                </Typography>
                <Typography>
                  数値目標の進捗をグラフで可視化。達成感を高めます。
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ 
                p: 4, 
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                }
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
                  <TrendingUpIcon sx={{ fontSize: 80, color: '#0131b4' }} />
                </Box>
                <Typography variant="h6" gutterBottom>
                  継続的な改善サイクル
                </Typography>
                <Typography>
                  定期的な振り返りで、学習効果を最大化します。
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* あなたの目的に合わせてセクション */}
      <Box sx={{ bgcolor: '#f5f5f5', py: 10 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" gutterBottom>
            あなたの目的に合わせて
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ 
                p: 4, 
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                }
              }}>
                <SchoolIcon sx={{ fontSize: 80, color: '#0131b4', mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  資格取得の学習記録
                </Typography>
                <Typography>
                  学習計画を立てて進捗を管理。目標達成をサポートします。
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ 
                p: 4, 
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                }
              }}>
                <MenuBookIcon sx={{ fontSize: 80, color: '#0131b4', mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  研究・学習内容の記録
                </Typography>
                <Typography>
                  参考URLを添付し、効率的に情報を管理。後で簡単に振り返ることができます。
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ 
                p: 4, 
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                }
              }}>
                <AutoStoriesIcon sx={{ fontSize: 80, color: '#0131b4', mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  読書記録
                </Typography>
                <Typography>
                  読んだ本の内容や感想を記録し、知識を深めます。
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
            <Button 
              variant="contained" 
              size="large"
              onClick={handleStartClick}
              sx={{ 
                bgcolor: '#0131b4', 
                color: 'white',
                fontSize: '1.2rem',
                padding: '12px 24px',
                '&:hover': {
                  bgcolor: '#1e90ff',
                }
              }}>
              今すぐ無料登録
            </Button>
          </Box>
        </Container>
      </Box>

      {/* ユーザーの声セクション */}
      <Box sx={{ bgcolor: '#ffffff', py: 10 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" gutterBottom>
            ユーザーの声
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="body1" gutterBottom>
                  "RefRecoを使い始めてから、振り返りの習慣が身につきました。目標達成への道筋が明確になりました！"
                </Typography>
                <Typography variant="subtitle1" align="right">
                  - 山田太郎さん（大学生）
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="body1" gutterBottom>
                  "OKRの考え方を取り入れた目標設定とリフレクションで、仕事の効率が大幅に上がりました。"
                </Typography>
                <Typography variant="subtitle1" align="right">
                  - 佐藤花子さん（会社員）
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="body1" gutterBottom>
                  "読書記録と振り返りを通じて、知識の定着が実感できます。学習効果が最大化されました。"
                </Typography>
                <Typography variant="subtitle1" align="right">
                  - 鈴木一郎さん（フリーランス）
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* 将来の展望セクション */}
      <Box sx={{ bgcolor: '#f5f5f5', py: 10 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" gutterBottom>
            RefRecoの未来
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2 }}>
            AIによるパーソナライズされたフィードバックや、あなただけのポートフォリオを自動作成する機能を近日公開予定です。
          </Typography>
        </Container>
      </Box>

      {/* 強力なコールトゥアクション */}
      <Box sx={{ bgcolor: '#ffffff', py: 10 }}>
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            あなたも<span style={{ color: '#0131b4' }}>RefReco</span>で目標達成と成長を加速させませんか？
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            効果的な振り返りと目標管理で、学習効果を最大化しましょう。
          </Typography>
          <Button 
            variant="contained" 
            size="large"
            onClick={handleStartClick}
            sx={{ 
              mt: 2, 
              bgcolor: '#0131b4', 
              color: 'white',
              fontSize: '1.2rem',
              padding: '12px 24px',
              '&:hover': {
                bgcolor: '#1e90ff',
              }
            }}>
            今すぐ無料登録
          </Button>
        </Container>
      </Box>

      {/* フッター */}
      <Box sx={{ bgcolor: '#333', color: '#fff', p: 6, mt: 10 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <Typography variant="h6" gutterBottom>
                  RefReco - リフレコ - 
                </Typography>
                <Typography variant="body2">
                  目標達成と成長を加速させる振り返りツール
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <IconButton
              href="https://x.com/Ref_Reco"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: '#fff' }}
            >
              <XIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="body2">
              © 2024 RefReco. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default LandingPage;