import React from 'react';
import { Paper, Typography, Box, Avatar } from '@mui/material';

const GreetingBubble = ({ message }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 3, px: 2 }}>
      <Avatar 
        src="/images/refreco-avatar.png"
        alt="リフレコ"
        sx={{ 
          width: 40, 
          height: 40,
          mr: 1,
        }} 
      />
      <Paper
        elevation={1}
        sx={{
          p: 2,
          maxWidth: '80%',
          borderRadius: 2,
          bgcolor: '#F8F9FA',
          color: '#2C3E50',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: -10,
            top: 10,
            border: '5px solid transparent',
            borderRight: `10px solid #F8F9FA`,
          }
        }}
      >
        <Typography variant="body1" color="inherit">
          {message}
        </Typography>
      </Paper>
    </Box>
  );
};

export default GreetingBubble;
