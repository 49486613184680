import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * スタイル付きのTableCellコンポーネント
 * @param {Object} theme - MUIのテーマオブジェクト
 * @returns {Object} スタイル付きのTableCellコンポーネント
 */
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));
