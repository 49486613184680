import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Autocomplete, Typography, IconButton, FormControl, InputLabel, Select, MenuItem, Paper, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns'; // parseISO関数をインポート
import ja from 'date-fns/locale/ja'; // 日本語ロケールをインポート
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import { handleAddLink, handleLinkChange, handleRemoveLink } from '../../utils/commonUtils';
import { goalService } from '../../services/goalService';
import { unitService } from '../../services/unitService';
import { supabase } from '../../utils/supabase';
import { useProjectData } from '../../hooks/useProjectData';
import { MDXEditor } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { getMDXEditorPlugins, getMDXEditorStyles } from '../../utils/mdxEditorConfig';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EditIcon from '@mui/icons-material/Edit';

/**
 * 目標ダイアログコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object|null} props.goal - 編集する目標オブジェクト（新規作成時はnull）
 * @param {Object|null} props.initialData - 目標の初期データ（新規作成時はnull）
 * @param {Array} props.subGoals - サブ目標のリスト（新規作成時は空）
 * @param {Function} props.onClose - ダイアログを閉じる際のコールバック
 * @param {Function} props.onSave - 目標保存時のコールバック
 * @param {Array} props.availableParentGoals - 選択可能な親目標のリスト
 * @param {Object|null} props.parentGoal - 親目標オブジェクト（新規作成時はnull）
 * @param {string|null} props.defaultProjectId - プロジェクトIDの初期値（新規作成時はnull）
 * @param {string|null} props.defaultParentGoalId - 親目標IDの初期値（新規作成時はnull）
 * @returns {React.Component} 目標ダイアログコンポーネント
 */
const GoalDialog = ({ 
  goal = null, 
  initialData = null, 
  subGoals = [], 
  onClose, 
  onSave, 
  availableParentGoals = [], 
  parentGoal = null,
  defaultProjectId = null,
  defaultParentGoalId = null
}) => {
  const [title, setTitle] = useState(initialData?.title || goal?.title || '');
  const [description, setDescription] = useState(initialData?.description || goal?.description || '');
  const [deadline, setDeadline] = useState(goal && goal.deadline ? parseISO(goal.deadline) : null);
  const [targetValue, setTargetValue] = useState(goal ? goal.target_value || '' : '');
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [customUnit, setCustomUnit] = useState('');
  const [referenceLinks, setReferenceLinks] = useState(goal && goal.reference_links ? goal.reference_links : [{ url: '', title: '' }]);
  const [status, setStatus] = useState(goal ? goal.status || 'not_started' : 'not_started');
  const [completeDate, setCompleteDate] = useState(goal && goal.complete_date ? parseISO(goal.complete_date) : null);
  const [projectId, setProjectId] = useState(
    goal ? goal.project_id : (defaultProjectId || '')
  );
  const [parentGoalId, setParentGoalId] = useState(
    goal ? goal.parent_goal_id : (defaultParentGoalId || '')
  );
  const { projects } = useProjectData();
  const [activeProjects, setActiveProjects] = useState([]);
  const mdxPlugins = getMDXEditorPlugins();
  const mdxStyles = getMDXEditorStyles();
  const [subGoalsList, setSubGoalsList] = useState(subGoals);
  const [editingSubGoalIndex, setEditingSubGoalIndex] = useState(null);

  useEffect(() => {
    fetchUnits();
    if (goal) {
      setProjectId(goal.project_id || '');
    }
    // アクティブなプロジェクトのみをフィルタリング
    setActiveProjects(projects.filter(project => !project.is_completed));
  }, [goal, projects]);

  const fetchUnits = async () => {
    try {
      const userId = await getUserId();
      const data = await unitService.fetchUnits(userId);
      setUnits(data);
      if (goal && goal.unit_id) {
        const currentUnit = data.find(unit => unit.id === goal.unit_id);
        setSelectedUnit(currentUnit || null);
      }
    } catch (error) {
      console.error('単位の取得に失敗しました:', error);
    }
  };

  const getUserId = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('ユーザーが見つかりません');
    return user.id;
  };

  const handleUnitChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setCustomUnit(newValue);
      setSelectedUnit(null);
    } else if (newValue && newValue.inputValue) {
      setCustomUnit(newValue.inputValue);
      setSelectedUnit(null);
    } else {
      setSelectedUnit(newValue);
      setCustomUnit('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userId = await getUserId();
      const utcDeadline = deadline 
        ? new Date(Date.UTC(deadline.getFullYear(), deadline.getMonth(), deadline.getDate()))
        : null;
      const utcCompleteDate = completeDate
        ? new Date(Date.UTC(completeDate.getFullYear(), completeDate.getMonth(), completeDate.getDate()))
        : null;

      let unitId = selectedUnit ? selectedUnit.id : null;

      if (customUnit && !selectedUnit) {
        const newUnit = await unitService.createUnit(customUnit, userId);
        unitId = newUnit.id;
      }

      const goalData = { 
        title, 
        description, 
        deadline: utcDeadline ? utcDeadline.toISOString() : null,
        target_value: targetValue ? parseFloat(targetValue) : null,
        unit_id: unitId,
        reference_links: referenceLinks.filter(link => link.url),
        status: status,
        complete_date: status === 'completed' ? utcCompleteDate.toISOString() : null,
        is_completed: status === 'completed',
        project_id: projectId || null,
        parent_goal_id: parentGoalId || null,
        user_id: userId
      };

      // サブ目標のデータを整形
      const formattedSubGoals = subGoalsList.map(subGoal => ({
        title: subGoal.title,
        description: subGoal.description,
        deadline: subGoal.deadline,
        target_value: subGoal.target_value ? parseFloat(subGoal.target_value) : null,
        unit_id: subGoal.unit_id,
        status: 'not_started',
        is_completed: false,
        reference_links: [],
        project_id: projectId || null,
        user_id: userId
      }));

      // メイン目標とサブ目標を一括で保存
      const result = await goalService.createGoalWithSubGoals(
        goal ? { ...goalData, id: goal.id } : goalData,
        formattedSubGoals
      );

      await onSave(result);
      onClose();
    } catch (error) {
      console.error('目標の保存中にエラーが発生しました:', error);
    }
  };

  const handleSubGoalEdit = (index) => {
    setEditingSubGoalIndex(index);
  };

  const handleSubGoalSave = (index, updatedSubGoal) => {
    const newSubGoals = [...subGoalsList];
    newSubGoals[index] = updatedSubGoal;
    setSubGoalsList(newSubGoals);
    setEditingSubGoalIndex(null);
  };

  const handleSubGoalDelete = (index) => {
    const newSubGoals = subGoalsList.filter((_, i) => i !== index);
    setSubGoalsList(newSubGoals);
  };

  const handleAddSubGoal = () => {
    const newSubGoal = { 
      title: '', 
      description: '', 
      deadline: null, 
      target_value: '', 
      unit_id: null 
    };
    setSubGoalsList([...subGoalsList, newSubGoal]);
    setEditingSubGoalIndex(subGoalsList.length);
  };

  // セクションスタイルを追加
  const sectionStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    borderRadius: 1,
    p: 2,
    mb: 3,
  };

  const sectionHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mb: 2,
  };

  // サブ目標のレンダリング
  const renderSubGoals = () => (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">サブ目標</Typography>
      {subGoalsList.map((subGoal, index) => (
        <Box key={index} sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
          {editingSubGoalIndex === index ? (
            <SubGoalEditForm
              subGoal={subGoal}
              onSave={(updatedSubGoal) => handleSubGoalSave(index, updatedSubGoal)}
              onCancel={() => setEditingSubGoalIndex(null)}
              units={units}
            />
          ) : (
            <>
              <Typography sx={{ flexGrow: 1 }}>{subGoal.title}</Typography>
              <IconButton onClick={() => handleSubGoalEdit(index)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleSubGoalDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </Box>
      ))}
      <Button onClick={handleAddSubGoal}>
        サブ目標を追加
      </Button>
    </Box>
  );

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{goal ? '目標の編集' : '新しい目標'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {/* 基本情報セクション */}
          <Paper sx={sectionStyle}>
            <Box sx={sectionHeaderStyle}>
              <Typography variant="h6">基本情報</Typography>
              <Tooltip title="目標の基本的な情報を入力してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Box>
            <FormControl fullWidth margin="normal">
              <InputLabel>プロジェクト</InputLabel>
              <Select
                value={projectId}
                onChange={(e) => setProjectId(e.target.value)}
                label="プロジェクト"
                size="small"
              >
                <MenuItem value=""><em>なし</em></MenuItem>
                {activeProjects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>親目標</InputLabel>
              <Select
                value={parentGoalId}
                onChange={(e) => setParentGoalId(e.target.value)}
                label="親目標"
                size="small"
              >
                <MenuItem value=""><em>なし</em></MenuItem>
                {availableParentGoals.map((parentGoal) => (
                  <MenuItem key={parentGoal.id} value={parentGoal.id}>
                    {parentGoal.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="目標名"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
              size="small"
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
              <DatePicker
                label="期限"
                value={deadline}
                onChange={(newValue) => setDeadline(newValue)}
                format="yyyy/MM/dd"
                slotProps={{ textField: { fullWidth: true, margin: 'normal', size: 'small' } }}
              />
            </LocalizationProvider>
            <FormControl fullWidth margin="normal">
              <InputLabel>状態</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="状態"
                size="small"
              >
                <MenuItem value="not_started">未着手</MenuItem>
                <MenuItem value="in_progress">進行中</MenuItem>
                <MenuItem value="completed">完了</MenuItem>
              </Select>
            </FormControl>
            {status === 'completed' && (
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                <DatePicker
                  label="完了日"
                  value={completeDate}
                  onChange={(newValue) => setCompleteDate(newValue)}
                  format="yyyy/MM/dd"
                  slotProps={{ textField: { fullWidth: true, margin: 'normal', size: 'small' } }}
                />
              </LocalizationProvider>
            )}
          </Paper>

          {/* 目標詳細セクション */}
          <Paper sx={sectionStyle}>
            <Box sx={sectionHeaderStyle}>
              <Typography variant="h6">目標詳細</Typography>
              <Tooltip title="目標の詳細な内容と数値目標を設定してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Box>
            <Typography variant="subtitle1" gutterBottom>目標概要</Typography>
            <Box sx={mdxStyles}>
              <MDXEditor
                markdown={description}
                onChange={(value) => setDescription(value)}
                plugins={mdxPlugins}
                contentEditableClassName="prose max-w-full"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="目標数値"
                type="number"
                value={targetValue}
                onChange={(e) => setTargetValue(e.target.value)}
                size="small"
                fullWidth
                sx={{ mb: 2 }}
              />
              <Autocomplete
                options={units}
                getOptionLabel={(option) => option.name}
                value={selectedUnit}
                onChange={handleUnitChange}
                onInputChange={(event, newInputValue) => {
                  setCustomUnit(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="単位" placeholder='選択または入力' size="small" />
                )}
                freeSolo
              />
            </Box>
          </Paper>

          {/* 参考リンクセクション */}
          <Paper sx={sectionStyle}>
            <Box sx={sectionHeaderStyle}>
              <Typography variant="h6">参考リンク</Typography>
              <Tooltip title="目標達成に参考となる資料やWebサイトのURLを追加してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Box>
            {referenceLinks.map((link, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <TextField
                  label="URL"
                  value={link.url}
                  onChange={(e) => handleLinkChange(index, 'url', e.target.value, referenceLinks, setReferenceLinks)}
                  size="small"
                  fullWidth
                  sx={{ mr: 1 }}
                />
                <TextField
                  label="タイトル"
                  value={link.title}
                  onChange={(e) => handleLinkChange(index, 'title', e.target.value, referenceLinks, setReferenceLinks)}
                  size="small"
                  fullWidth
                  sx={{ mr: 1 }}
                />
                <IconButton onClick={() => handleRemoveLink(index, referenceLinks, setReferenceLinks)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              startIcon={<LinkIcon />}
              onClick={() => handleAddLink(setReferenceLinks)}
              variant="outlined"
              size="small"
              fullWidth
            >
              リンクを追加
            </Button>
          </Paper>

          {/* サブ目標のプレビューを追加 */}
          {renderSubGoals()}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SubGoalEditForm = ({ subGoal, onSave, onCancel, units }) => {
  const [title, setTitle] = useState(subGoal.title);
  const [description, setDescription] = useState(subGoal.description);
  const [deadline, setDeadline] = useState(subGoal.deadline ? new Date(subGoal.deadline) : null);
  const [targetValue, setTargetValue] = useState(subGoal.target_value || '');
  const [selectedUnit, setSelectedUnit] = useState(units.find(unit => unit.id === subGoal.unit_id) || null);

  const handleSave = () => {
    onSave({
      title,
      description,
      deadline: deadline ? deadline.toISOString() : null,
      target_value: targetValue ? parseFloat(targetValue) : null,
      unit_id: selectedUnit ? selectedUnit.id : null,
      status: 'not_started',  // デフォルトのステータスを設定
      reference_links: [],    // 空の参照リンク配列を設定
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <TextField
        label="サブ目標タイトル"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
      />
      <TextField
        label="説明"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        multiline
        rows={2}
        fullWidth
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <DatePicker
          label="期限"
          value={deadline}
          onChange={(newValue) => setDeadline(newValue)}
          format="yyyy/MM/dd"
          slotProps={{ 
            textField: { 
              fullWidth: true,
              size: 'small'
            } 
          }}
        />
      </LocalizationProvider>
      <TextField
        label="目標数値"
        type="number"
        value={targetValue}
        onChange={(e) => setTargetValue(e.target.value)}
        fullWidth
      />
      <Autocomplete
        options={units}
        getOptionLabel={(option) => option.name}
        value={selectedUnit}
        onChange={(event, newValue) => {
          setSelectedUnit(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="単位" />}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Button onClick={onCancel}>キャンセル</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          保存
        </Button>
      </Box>
    </Box>
  );
};

export default GoalDialog;
