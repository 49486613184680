import React, { useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Checkbox,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const TaskItem = ({ task, onToggle, onEdit, onDelete, canEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(task.content);

  const handleEditSubmit = () => {
    if (editContent.trim() !== task.content) {
      onEdit(task.id, editContent.trim());
    }
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleEditSubmit();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditContent(task.content);
    }
  };

  return (
    <ListItem
      secondaryAction={
        canEdit && (
          <>
            {isEditing ? (
              <>
                <IconButton edge="end" onClick={handleEditSubmit}>
                  <CheckIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={() => {
                    setIsEditing(false);
                    setEditContent(task.content);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton edge="end" onClick={() => setIsEditing(true)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" onClick={() => onDelete(task.id)}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </>
        )
      }
    >
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={task.is_completed}
          onChange={(e) => onToggle(task.id, e.target.checked, task.content)}
        />
      </ListItemIcon>
      {isEditing ? (
        <TextField
          fullWidth
          value={editContent}
          onChange={(e) => setEditContent(e.target.value)}
          onKeyDown={handleKeyPress}
          autoFocus
        />
      ) : (
        <ListItemText
          primary={task.content}
          sx={{
            textDecoration: task.is_completed ? 'line-through' : 'none',
            color: task.is_completed ? 'text.secondary' : 'text.primary',
          }}
        />
      )}
    </ListItem>
  );
};

export default TaskItem;