/**
 * @fileoverview 目標のタイムラインを表示するチャートコンポーネント
 * @module GoalTimelineChart
 */

import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, Tooltip, Chip, FormControlLabel, Switch, IconButton } from '@mui/material';
import { format, isValid, parseISO, isSameDay, isSameWeek, isSameMonth, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import { ja } from 'date-fns/locale';
import DateRangePicker from './common/DateRangePicker';
import TimeRangeSelector from './common/TimeRangeSelector';
import GoalProgress from '../common/GoalProgress';
import ReflectionDialog from '../reflections/ReflectionDialog';
import ReflectionRemoveDialog from '../reflections/ReflectionRemoveDialog';
import { useReflectionData } from '../../hooks/useReflectionData';
import { useGoalData } from '../../hooks/useGoalData';
import { getNextDate, getSymmetricalDateRange } from './common/chartUtils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GoalTooltip from '../common/GoalTooltip';
import SlidingPage from '../common/SlidingPage';
import GoalDetailPage from '../../pages/GoalDetailPage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; // 右向き矢印に変更
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * 目標のタイムラインを表示するチャートコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.goals - 目標データの配列
 * @param {Array} props.completedGoals - 完了した目標データの配列
 * @param {Array} props.reflections - 振り返りデータの配列
 * @param {Function} props.onEditGoal - 目標編集時のコールバック関数
 * @param {Function} props.onGoalClick - 目標クリック時のコールバック関数
 * @param {Function} props.onReflectionClick - 振り返りクリック時のコールバック関数
 * @param {Function} props.onReflectionChange - 振り返り変更時のコールバック関数
 * @returns {JSX.Element} GoalTimelineChartコンポーネント
 */
const GoalTimelineChart = ({ goals, completedGoals, reflections, onEditGoal, onReflectionClick, onReflectionChange }) => {
  const [openTooltip, setOpenTooltip] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [selectedReflection, setSelectedReflection] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [timeRange, setTimeRange] = useState('daily');
  const [isGoalDetailOpen, setIsGoalDetailOpen] = useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState(null);
  const [showCompletedGoals, setShowCompletedGoals] = useState(false);  // 追加
  const [expandedGoals, setExpandedGoals] = useState(new Set());  // 追加
  const navigate = useNavigate();
  const location = useLocation();

  const { handleEditReflection, handleDeleteReflection } = useReflectionData();
  const { fetchGoals } = useGoalData();

  useEffect(() => {
    const { startDate: newStartDate, endDate: newEndDate } = getSymmetricalDateRange(timeRange);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [timeRange]);

  useEffect(() => {
    updateDateRange();
  }, [startDate, endDate, timeRange]);

  /**
   * 日付範囲を更新する
   */
  const updateDateRange = () => {
    const start = parseISO(startDate);
    const end = parseISO(endDate);
    const range = [];
    let currentDate = start;
    while (currentDate <= end) {
      range.push(currentDate);
      currentDate = getNextDate(currentDate, timeRange);
    }
    setDateRange(range);
  };

  /**
   * 時間範囲の変更を処理する
   * @param {Event} event - イベントオブジェクト
   * @param {string} newTimeRange - 新しい時間範囲
   */
  const handleTimeRangeChange = (event, newTimeRange) => {
    if (newTimeRange !== null) {
      setTimeRange(newTimeRange);
      const { startDate: newStartDate, endDate: newEndDate } = getSymmetricalDateRange(newTimeRange);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };

  /**
   * 日付の変更を処理る
   * @param {Event} event - イベントオブジェクト
   * @param {boolean} isStart - 開始日かどうか
   */
  const handleDateChange = (event, isStart) => {
    const { value } = event.target;
    if (isStart) {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const activeGoals = goals.filter(goal => !goal.is_completed);

  /**
   * 目標の状態に応じた背景色を取得する
   * @param {Object} goal - 目標オブジェクト
   * @returns {string} 背景色
   */
  const getGoalColor = (goal) => {
    if (goal.is_completed) {
      return '#ffcccb'; // 薄い赤色（完了状態）
    }
    switch (goal.status) {
      case 'in_progress':
        return '#e3f2fd'; // 水色
      case 'not_started':
      default:
        return '#f5f5f5'; // 薄い灰色
    }
  };

  /**
   * 目標の状態を文字列で取得する
   * @param {Object} goal - 目標オブジェクト
   * @returns {string} 目標の状態
   */
  const getGoalStatus = (goal) => {
    if (goal.is_completed) {
      return '完了';
    }
    return goal.status === 'in_progress' ? '進行中' : '未着手';
  };

  /**
   * 日付を指定されたフォーマットで整形する
   * @param {string} dateString - 日付文字列
   * @returns {string} フォーマットされた日付文字列
   */
  const formatDate = (dateString) => {
    if (!dateString) return '日付未設定';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'yyyy/MM/dd') : '無効な日付';
  };

  /**
   * ツールチップを開く
   * @param {string} goalId - 目標ID
   */
  const handleTooltipOpen = (goalId) => {
    setOpenTooltip(goalId);
  };

  /**
   * ツールチップを閉じる
   */
  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  /**
   * 目標の進捗を表示するかどうかを判定する
   * @param {Object} goal - 目標オブジェクト
   * @returns {boolean} 進捗を表示するかどうか
   */
  const shouldShowProgress = (goal) => {
    return goal.target_value && parseFloat(goal.target_value) > 0;
  };

  const reflectionsByDate = reflections.reduce((acc, reflection) => {
    const date = format(parseISO(reflection.date), 'yyyy-MM-dd');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(reflection);
    return acc;
  }, {});

  /**
   * 振り返りのツールチップコンポーネント
   * @param {Object} props - コンポーネントのプロパティ
   * @param {Object} props.reflection - 振り返りオブジェクト
   * @returns {JSX.Element} ReflectionTooltipコンポーネント
   */
  const ReflectionTooltip = ({ reflection }) => (
    <Box sx={{ maxWidth: '300px' }}>
      <Typography variant="body2"><strong>日付:</strong> {formatDate(reflection.date)}</Typography>
      {reflection.start_time && reflection.end_time && (
        <Typography variant="body2">
          <strong>時間:</strong> {format(parseISO(reflection.start_time), 'HH:mm')} - {format(parseISO(reflection.end_time), 'HH:mm')}
        </Typography>
      )}
      <Typography variant="body2" component="div">
        <strong>達成したこと:</strong>{' '}
        <div style={{ 
          whiteSpace: 'pre-wrap', 
          fontFamily: 'inherit', 
          fontSize: 'inherit',
          marginTop: '4px'
        }}>
          {reflection.achievement || '内容なし'}
        </div>
      </Typography>
      <Typography variant="body2" component="div">
        <strong>学んだこと:</strong>{' '}
        <div style={{ 
          whiteSpace: 'pre-wrap', 
          fontFamily: 'inherit', 
          fontSize: 'inherit',
          marginTop: '4px'
        }}>
          {reflection.learning || '内容なし'}
        </div>
      </Typography>
      <Typography variant="body2" component="div">
        <strong>改善点・次にやること:</strong>{' '}
        <div style={{ 
          whiteSpace: 'pre-wrap', 
          fontFamily: 'inherit', 
          fontSize: 'inherit',
          marginTop: '4px'
        }}>
          {reflection.improvement || '内容なし'}
        </div>
      </Typography>
    </Box>
  );

  /**
   * 振り返りをクリックしたときの処理
   * @param {Object} reflection - 振り返りオブジェクト
   */
  const handleReflectionClick = (reflection) => {
    setSelectedReflection(reflection);
    setIsEditDialogOpen(true);
  };

  /**
   * 編集ダイアログを閉じる
   */
  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    setSelectedReflection(null);
  };

  /**
   * 削除ダイアログを開く
   */
  const handleRemoveDialogOpen = () => {
    setIsEditDialogOpen(false);
    setIsRemoveDialogOpen(true);
  };

  /**
   * 削除ダイアログを閉じる
   */
  const handleRemoveDialogClose = () => {
    setIsRemoveDialogOpen(false);
    setSelectedReflection(null);
  };

  /**
   * 振り返りを保存する
   * @param {Object} updatedReflection - 更新された振り返りオブジェクト
   */
  const handleReflectionSave = async (updatedReflection) => {
    try {
      await handleEditReflection(updatedReflection);
      onReflectionChange(); // 親コンポーネントに変更を通知
      handleEditDialogClose();
    } catch (error) {
      console.error('振り返りの更新に失敗しました:', error);
    }
  };

  /**
   * 振り返りを削除する
   * @param {Object} reflection - 削除する振り返りオブジェクト
   */
  const handleReflectionRemove = async (reflection) => {
    try {
      await handleDeleteReflection(reflection);
      onReflectionChange(); // 親コンポーネントに変更を通知
      handleRemoveDialogClose();
    } catch (error) {
      console.error('振り返りの削除に失敗しました:', error);
    }
  };

  /**
   * 振り返りを開始時間または作成日時でソートする
   * @param {Array} reflections - 振り返りの配列
   * @returns {Array} ソートされた振り返りの配列
   */
  const sortReflectionsByStartTimeOrCreatedAt = (reflections) => {
    return reflections.sort((a, b) => {
      const aTime = a.start_time ? parseISO(a.start_time) : parseISO(a.created_at);
      const bTime = b.start_time ? parseISO(b.start_time) : parseISO(b.created_at);
      return aTime.getTime() - bTime.getTime();
    });
  };

  /**
   * 配列を指定されたサイズのチャンクに分割する
   * @param {Array} arr - 分割する配列
   * @param {number} size - チャンクのサイズ
   * @returns {Array} チャンクの配列
   */
  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  };

  /**
   * 日付のラベルを取得する
   * @param {Date} date - 日付オブジェクト
   * @returns {string} 日付ラベル
   */
  const getDateLabel = (date) => {
    switch (timeRange) {
      case 'daily':
        return `${format(date, 'd')}(${format(date, 'E', { locale: ja })})`;
      case 'weekly':
        return `第${format(date, 'w', { locale: ja })}週`;
      case 'monthly':
        return format(date, 'M月', { locale: ja });
    }
  };

  /**
   * 期限が指定された範囲内にあるかどうかを判定する
   * @param {string} deadline - 期限の日付文字列
   * @param {Date} date - 比較する日付オブジェクト
   * @returns {boolean} 期限が範囲内にあるかどうか
   */
  const isDeadlineInRange = (deadline, date) => {
    if (!deadline) return false;
    const deadlineDate = parseISO(deadline);
    switch (timeRange) {
      case 'daily':
        return isSameDay(deadlineDate, date);
      case 'weekly':
        return isSameDay(startOfWeek(deadlineDate), startOfWeek(date));
      case 'monthly':
        return isSameDay(startOfMonth(deadlineDate), startOfMonth(date));
    }
  };

  /**
   * 指定された範囲の振り返りを取得する
   * @param {Date} date - 日付オブジェクト
   * @param {string} goalId - 目標ID
   * @returns {Array} 振り返りの配列
   */
  const getReflectionsForRange = (date, goalId) => {
    const dateString = format(date, 'yyyy-MM-dd');
    let reflectionsForRange = [];

    switch (timeRange) {
      case 'daily':
        reflectionsForRange = reflectionsByDate[dateString] || [];
        break;
      case 'weekly':
        const weekStart = startOfWeek(date);
        const weekEnd = endOfWeek(date);
        reflectionsForRange = reflections.filter(r => {
          const reflectionDate = parseISO(r.date);
          return reflectionDate >= weekStart && reflectionDate <= weekEnd;
        });
        break;
      case 'monthly':
        const monthStart = startOfMonth(date);
        const monthEnd = endOfMonth(date);
        reflectionsForRange = reflections.filter(r => {
          const reflectionDate = parseISO(r.date);
          return reflectionDate >= monthStart && reflectionDate <= monthEnd;
        });
        break;
    }

    return reflectionsForRange.filter(r => r.goal_id === goalId);
  };

  /**
   * 現在の期間かどうかを判定する
   * @param {Date} date - 日付オブジェクト
   * @returns {boolean} 現在の期間かどうか
   */
  const isCurrentPeriod = (date) => {
    switch (timeRange) {
      case 'daily':
        return isSameDay(date, new Date());
      case 'weekly':
        return isSameWeek(date, new Date(), { weekStartsOn: 1 }); // 月曜日始まりの週を使用
      case 'monthly':
        return isSameMonth(date, new Date());
    }
  };

  /**
   * 完了日が指定された範囲内にあるかどうかを判定する
   * @param {string} completeDate - 完了日の日付文字列
   * @param {Date} date - 比較する日付オブジェクト
   * @returns {boolean} 完了日が範囲内にあるかどうか
   */
  const isCompleteDateInRange = (completeDate, date) => {
    if (!completeDate) return false;
    const completeDateObj = parseISO(completeDate);
    switch (timeRange) {
      case 'daily':
        return isSameDay(completeDateObj, date);
      case 'weekly':
        return isSameWeek(completeDateObj, date, { weekStartsOn: 1 });
      case 'monthly':
        return isSameMonth(completeDateObj, date);
    }
  };

  const allGoals = showCompletedGoals 
    ? [...goals, ...completedGoals.filter(goal => 
        dateRange.some(date => isCompleteDateInRange(goal.complete_date, date))
      )]
    : goals;

  /**
   * 目標をクリックしたときの処理
   * @param {string} goalId - 目標ID
   */
  const handleGoalClick = (goalId) => {
    navigate(`${location.pathname}?goal=${goalId}`);
  };

  /**
   * 目標詳細ページを閉じる
   */
  const handleGoalDetailClose = () => {
    setIsGoalDetailOpen(false);
    setSelectedGoalId(null);
  };

  /**
   * 目標の展開状態を切り替える
   * @param {string} goalId - 目標ID
   */
  const handleExpandToggle = (goalId) => {
    const newExpanded = new Set(expandedGoals);
    if (newExpanded.has(goalId)) {
      newExpanded.delete(goalId);
    } else {
      newExpanded.add(goalId);
    }
    setExpandedGoals(newExpanded);
  };

  /**
   * 子目標を持っているかどうかを確認する
   * @param {string} goalId - 目標ID
   * @returns {boolean} 子目標を持っているかどうか
   */
  const hasChildGoals = (goalId) => {
    return allGoals.some(goal => goal.parent_goal_id === goalId);
  };

  /**
   * 表示する目標をフィルタリングする
   * @returns {Array} フィルタリングされた目標の配列
   */
  const getFilteredGoals = () => {
    const result = [];
    
    // 最上位の目標（親を持たない目標）を取得
    const topLevelGoals = allGoals.filter(goal => !goal.parent_goal_id);
    
    // 再帰的に目標とその子目標を追加する関数
    const addGoalAndChildren = (goal) => {
      result.push(goal);
      
      // 目標が展開されている場合、その直下の子目標を追加
      if (expandedGoals.has(goal.id)) {
        const childGoals = allGoals.filter(g => g.parent_goal_id === goal.id);
        childGoals.forEach(childGoal => {
          addGoalAndChildren(childGoal);
        });
      }
    };

    // トップレベルの目標から再帰的に追加
    topLevelGoals.forEach(goal => {
      addGoalAndChildren(goal);
    });

    return result;
  };

  /**
   * 目標の階層レベルを計算する
   * @param {Object} goal - 目標オブジェクト
   * @param {Array} allGoals - すべての目標の配列
   * @returns {number} インデントレベル
   */
  const calculateIndentLevel = (goal, allGoals) => {
    let level = 0;
    let currentGoal = goal;
    
    while (currentGoal.parent_goal_id) {
      level++;
      currentGoal = allGoals.find(g => g.id === currentGoal.parent_goal_id);
      if (!currentGoal) break;
    }
    
    return level;
  };

  /**
   * 階層レベルに応じたフォントサイズを計算する
   * @param {Object} goal - 目標オブジェクト
   * @param {Array} allGoals - すべての目標の配列
   * @returns {string} フォントサイズ
   */
  const calculateFontSize = (goal, allGoals) => {
    const level = calculateIndentLevel(goal, allGoals);
    const baseFontSize = 1; // 基準フォントサイズ（rem）
    const scaleFactor = 0.05; // 階層ごとの縮小率
    
    return `${baseFontSize - (level * scaleFactor)}rem`;
  };

  return (
    <Box sx={{ mt: 4, overflowX: 'auto' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2 
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={showCompletedGoals}
                onChange={(e) => setShowCompletedGoals(e.target.checked)}
                name="showCompletedGoals"
              />
            }
            label="完了した目標を表示"
          />
        </Box>
        <Typography variant="h6">
          目標タイムライン
        </Typography>
        <Box sx={{ width: '200px' }} /> {/* タイトルを中央に配置するためのスペーサー */}
      </Box>

      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        <TimeRangeSelector timeRange={timeRange} onTimeRangeChange={handleTimeRangeChange} />
      </Box>

      <DateRangePicker
        timeRange={timeRange}
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChange}
      />
      <Paper elevation={3} sx={{ p: 2, minWidth: 800 }}>
        <Box sx={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
          <Box sx={{ width: '200px', fontWeight: 'bold', p: 1 }}>目標</Box>
          {dateRange.map((date, index) => (
            <Box key={index} sx={{ 
              flex: 1, 
              textAlign: 'center', 
              fontWeight: 'bold', 
              p: 1,
              position: 'relative',
              borderRight: index < dateRange.length - 1 ? '1px dashed #ccc' : 'none',
            }}>
              <Typography
                sx={{
                  display: 'inline-block',
                  width: 'auto',
                  height: '24px',
                  lineHeight: '24px',
                  padding: '0 4px',
                  borderRadius: '4px',
                  backgroundColor: isCurrentPeriod(date) ? 'rgba(255, 0, 0, 0.1)' : 'inherit', // 当日/当週/当月の日付を薄い赤で表示
                }}
              >
                {getDateLabel(date)}
              </Typography>
            </Box>
          ))}
        </Box>
        {getFilteredGoals().map((goal, index) => (
          <Box 
            key={goal.id} 
            sx={{ 
              display: 'flex', 
              borderBottom: '1px solid #e0e0e0',
              '&:not(:last-child)': {
                borderBottom: '2px solid #bdbdbd',
              },
              opacity: goal.is_completed ? 0.8 : 1,
              // インデントのレベルを親目標の階層に基づいて計算
              pl: calculateIndentLevel(goal, allGoals) * 4,
            }}
          >
            <Tooltip 
              title={<GoalTooltip goal={goal} />}
              arrow
              placement="top"
            >
              <Box 
                sx={{ 
                  width: '200px', 
                  p: 1, 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  backgroundColor: getGoalColor(goal),
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  },
                  borderLeft: '4px solid',
                  borderLeftColor: goal.is_completed ? '#ff4081' : (goal.status === 'in_progress' ? '#2196f3' : '#9e9e9e'),
                }}
              >
                {/* 展開/折りたたみボタン */}
                {hasChildGoals(goal.id) && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleExpandToggle(goal.id);
                    }}
                    sx={{ p: 0.5 }}
                  >
                    {expandedGoals.has(goal.id) ? (
                      <ExpandMoreIcon fontSize="small" />
                    ) : (
                      <ChevronRightIcon fontSize="small" />
                    )}
                  </IconButton>
                )}
                <Box onClick={() => handleGoalClick(goal.id)} sx={{ flex: 1 }}>
                  <Typography variant="body2" sx={{ 
                    fontWeight: 'bold',
                    // フォントサイズを階層レベルに応じて調整
                    fontSize: calculateFontSize(goal, allGoals),
                  }}>
                    {goal.title}
                  </Typography>
                  {shouldShowProgress(goal) && (
                    <GoalProgress goal={goal} height={4} showPercentage={true} />
                  )}
                </Box>
              </Box>
            </Tooltip>
            {dateRange.map((date, index) => {
              const isDeadlineDay = isDeadlineInRange(goal.deadline, date);
              const isCompleteDay = isCompleteDateInRange(goal.complete_date, date);
              const goalReflections = getReflectionsForRange(date, goal.id);
              const reflectionRows = chunkArray(goalReflections, 3);

              return (
                <Box 
                  key={index} 
                  sx={{ 
                    flex: 1, 
                    p: 1, 
                    position: 'relative',
                    minHeight: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRight: index < dateRange.length - 1 ? '1px dashed #ccc' : 'none',
                  }}
                >
                  <Box sx={{ width: '100%', height: '16px', position: 'relative' }}>
                    {isDeadlineDay && (
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography variant="body2"><strong>{goal.title}</strong></Typography>
                            <Typography variant="body2">期限: {formatDate(goal.deadline)}</Typography>
                          </React.Fragment>
                        }
                        arrow
                        placement="top"
                      >
                        <Box
                          onClick={() => onEditGoal(goal)}
                          sx={{
                            position: 'absolute',
                            top: '2px',
                            left: isCompleteDay ? '40%' : '50%', // 完了アイコンがある場合は左に寄せる
                            transform: 'translateX(-50%) rotate(45deg)',
                            width: '12px',
                            height: '12px',
                            backgroundColor: '#ff4081',
                            cursor: 'pointer',
                            zIndex: 2, // デッドラインアイコンを前面に表示
                          }}
                        />
                      </Tooltip>
                    )}
                    {isCompleteDay && (
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography variant="body2"><strong>{goal.title}</strong></Typography>
                            <Typography variant="body2">完了日: {formatDate(goal.complete_date)}</Typography>
                          </React.Fragment>
                        }
                        arrow
                        placement="top"
                      >
                        <CheckCircleIcon
                          sx={{
                            position: 'absolute',
                            top: '2px',
                            left: isDeadlineDay ? '60%' : '50%', // デッドラインアイコンがある場合は右に寄せる
                            transform: 'translateX(-50%)',
                            color: '#4caf50',
                            fontSize: '16px',
                            zIndex: 1,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                  <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                    width: '100%',
                    minHeight: '24px',
                  }}>
                    {reflectionRows.map((row, rowIndex) => (
                      <Box 
                        key={rowIndex}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          width: '100%',
                          mb: 1,
                        }}
                      >
                        {row.map((reflection) => (
                          <Tooltip
                            key={reflection.id}
                            title={<ReflectionTooltip reflection={reflection} />}
                            arrow
                            placement="top"
                          >
                            <Chip
                              label="振"
                              size="small"
                              onClick={() => handleReflectionClick(reflection)}
                              sx={{
                                backgroundColor: '#4caf50',
                                color: 'white',
                                fontSize: '0.7rem',
                                height: '20px',
                                margin: '0 1px',
                              }}
                            />
                          </Tooltip>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </Box>
              );
            })}
          </Box>
        ))}
      </Paper>
      {isEditDialogOpen && (
        <ReflectionDialog
          reflection={selectedReflection}
          onClose={handleEditDialogClose}
          onSave={handleReflectionSave}
          open={isEditDialogOpen}
          goal={goals.find(g => g.id === selectedReflection.goal_id)}
        />
      )}
      {isRemoveDialogOpen && (
        <ReflectionRemoveDialog
          reflection={selectedReflection}
          onClose={handleRemoveDialogClose}
          onRemove={handleReflectionRemove}
        />
      )}
      <SlidingPage isOpen={isGoalDetailOpen} onClose={handleGoalDetailClose}>
        {selectedGoalId && (
          <GoalDetailPage
            id={selectedGoalId}
            onClose={handleGoalDetailClose} // ここでhandleGoalDetailCloseを渡す
          />
        )}
      </SlidingPage>
    </Box>
  );
};

export default GoalTimelineChart;
