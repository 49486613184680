/**
 * @fileoverview タスクサービス
 * @description タスクの取得、作成、更新、削除を行うサービス
 */

import { supabase } from '../utils/supabase';

export const taskService = {
  /**
   * ユーザーのタスクを取得する
   * @param {string} userId - ユーザーID
   * @param {string} goalId - 目標ID
   * @returns {Promise<Array>} タスクの配列
   */
  async fetchTasks(userId, goalId) {
    const { data, error } = await supabase
      .from('tasks')
      .select(`
        id,
        created_at,
        user_id,
        goal_id,
        content,
        is_completed,
        updated_at
      `)
      .eq('user_id', userId)
      .eq('goal_id', goalId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('タスクの取得に失敗しました:', error);
      throw error;
    }

    return data;
  },

  /**
   * 新しいタスクを作成する
   * @param {Object} taskData - タスクデータ
   * @returns {Promise<Object>} 作成されたタスク
   */
  async createTask(taskData) {
    const { data, error } = await supabase
      .from('tasks')
      .insert({
        user_id: taskData.user_id,
        goal_id: taskData.goal_id,
        content: taskData.content,
        is_completed: taskData.is_completed,
        updated_at: new Date()
      })
      .select(`
        id,
        created_at,
        user_id,
        goal_id,
        content,
        is_completed,
        updated_at
      `);

    if (error) {
      console.error('タスクの作成に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * タスクを更新する
   * @param {string} taskId - タスクID
   * @param {Object} updatedTask - 更新するタスクデータ
   * @returns {Promise<Object>} 更新されたタスク
   */
  async updateTask(taskId, updatedTask) {
    const { data, error } = await supabase
      .from('tasks')
      .update({
        ...updatedTask,
        updated_at: new Date()
      })
      .eq('id', taskId)
      .select(`
        id,
        created_at,
        user_id,
        goal_id,
        content,
        is_completed,
        updated_at
      `);

    if (error) {
      console.error('タスクの更新に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * タスクを削除する
   * @param {string} taskId - タスクID
   */
  async deleteTask(taskId) {
    const { error } = await supabase
      .from('tasks')
      .delete()
      .eq('id', taskId);

    if (error) {
      console.error('タスクの削除に失敗しました:', error);
      throw error;
    }
  }
};
