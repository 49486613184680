/**
 * @fileoverview 目標完了ダイアログコンポーネント
 * 
 * このファイルは、目標を完了としてマークするためのダイアログコンポーネントを定義します。
 * ユーザーが目標の完了日を選択し、確認できるようにします。
 * 
 * @requires React
 * @requires @mui/material
 * @requires @mui/x-date-pickers
 * @requires ../../utils/supabase
 */

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { supabase } from '../../utils/supabase';

/**
 * 目標完了ダイアログコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.goal - 完了する目標オブジェクト
 * @param {Function} props.onClose - ダイアログを閉じる際のコールバック
 * @param {Function} props.onUpdate - 目標更新後のコールバック
 * @returns {React.Component} 目標完了ダイアログコンポーネント
 */
const CompleteDialog = ({ goal, onClose, onUpdate }) => {
  const [completeDate, setCompleteDate] = useState(new Date());

  const handleCompleteConfirm = async () => {
    if (!goal || !completeDate) return;

    const utcCompleteDate = new Date(Date.UTC(completeDate.getFullYear(), completeDate.getMonth(), completeDate.getDate()));

    const { error } = await supabase
      .from('goals')
      .update({ 
        is_completed: true,
        complete_date: utcCompleteDate.toISOString()
      })
      .eq('id', goal.id);

    if (error) {
      console.error('目標の完了に失敗しました:', error);
    } else {
      onUpdate();
      onClose();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>目標完了日の選択</DialogTitle>
        <DialogContent>
          <DatePicker
            label="完了日"
            value={completeDate}
            onChange={(newValue) => setCompleteDate(newValue)}
            format="yyyy/MM/dd"
            slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
            views={['year', 'month', 'day']}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button onClick={handleCompleteConfirm} color="primary">
            完了
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CompleteDialog;
