/**
 * @file KanbanBoard.js
 * @description カンバンボードを表示するためのReactコンポーネント
 * 
 * このコンポーネントは、ドラッグアンドドロップ機能を持つカンバンボードを実装します。
 * 未着手、進行中の列があり、各列に項目を表示します。
 * 
 * @requires React
 * @requires react-beautiful-dnd
 * @requires @mui/material
 */

import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Paper, Typography, Grid } from '@mui/material';

/**
 * KanbanBoardは、ドラッグアンドドロップ可能なカンバンボードを表示するReactコンポーネントです。
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.items - 表示する項目の配列
 * @param {Function} props.onDragEnd - ドラッグ終了時のコールバック関数
 * @param {React.Component} props.itemComponent - 各項目を表示するためのコンポーネント
 * @returns {JSX.Element} カンバンボードを表示するGrid要素
 */
const KanbanBoard = ({ items, onDragEnd, itemComponent: ItemComponent }) => {
  const columns = [
    { id: 'not_started', title: '未着手', color: '#f0f0f0' }, // 灰色
    { id: 'in_progress', title: '進行中', color: '#e3f2fd' }, // 水色
    // { id: 'in_review', title: 'レビュー中', color: '#fff' }, // 白色（変更なし）
  ];

  // statusがNULLの場合は'not_started'として扱う
  const getStatus = (item) => item.status || 'not_started';

  // 完了していない目標のみをフィルタリング
  const activeItems = items.filter(item => !item.is_completed);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box sx={{ overflowX: 'auto', pb: 2 }}>
        <Grid 
          container 
          spacing={2} 
          sx={{ 
            display: 'flex', 
            flexWrap: 'nowrap', 
            width: { xs: '170%', sm: '100%' } 
          }}
        >
          {columns.map((column) => (
            <Grid item xs={6} key={column.id} sx={{ flexShrink: 0 }}>
              <Paper 
                elevation={3} 
                sx={{ 
                  p: 2, 
                  height: '100%', 
                  backgroundColor: column.color, // 列の背景色を設定
                  minHeight: '200px',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {column.title}
                </Typography>
                <Droppable droppableId={column.id}>
                  {(provided) => (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      sx={{ minHeight: 200 }}
                    >
                      {activeItems
                        .filter((item) => getStatus(item) === column.id)
                        .map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{ mb: 2 }}
                              >
                                <ItemComponent item={item} />
                              </Box>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </DragDropContext>
  );
};

export default KanbanBoard;
