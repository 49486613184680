/**
 * @fileoverview アクティビティサービス
 * @description ユーザーの目標と振り返りを取得し、アクティビティとして結合するサービス
 */

import { goalService } from './goalService';
import { reflectionService } from './reflectionService';

export const activityService = {
  /**
   * ユーザーのアクティビティを取得する
   * @param {string} userId - ユーザーID
   * @param {string|null} goalId - 目標ID (オプション)
   * @param {string|null} projectId - プロジェクトID (オプション)
   * @returns {Promise<Array>} アクティビティの配列
   * @throws {Error} データ取得に失敗した場合
   */
  async fetchActivities(userId, goalId = null, projectId = null) {
    try {
      const [goals, reflections] = await Promise.all([
        goalService.fetchGoals(userId, null, goalId, projectId),
        reflectionService.fetchReflections(userId, goalId, projectId)
      ]);

      return this.combineActivities(goals, reflections);
    } catch (error) {
      console.error('アクティビティデータの取得に失敗しました:', error);
      throw error;
    }
  },

  /**
   * 目標と振り返りを結合してアクティビティを生成する
   * @param {Array} goals - 目標の配列
   * @param {Array} reflections - 振り返りの配列
   * @returns {Array} 結合されたアクティビティの配列
   */
  combineActivities(goals, reflections) {
    return [
      ...goals.map(goal => ({ 
        ...goal, 
        type: 'goal',
        unit: goal.units
      })),
      ...reflections.map(reflection => ({ 
        ...reflection, 
        type: 'reflection',
        unit: reflection.goals.units
      }))
    ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }
};
