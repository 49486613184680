import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { format } from 'date-fns';
import { ReferenceLinks } from '../../utils/commonUtils';
import ReactMarkdown from 'react-markdown';

/**
 * プロジェクトの詳細情報を表示するカードコンポーネント
 * 
 * @component
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.project - プロジェクトの情報
 * @param {string} props.project.name - プロジェクト名
 * @param {string} props.project.description - プロジェクトの説明
 * @param {string} props.project.start_date - プロジェクトの開始日（ISO 8601形式）
 * @param {string} props.project.end_date - プロジェクトの終了日（ISO 8601形式）
 * @param {boolean} props.project.is_completed - プロジェクトが完了しているかどうか
 * @param {string} props.project.complete_date - プロジェクトの完了日（ISO 8601形式）
 * @param {Array} props.project.reference_links - 参考リンクの配列
 * @param {function} props.onEditClick - 編集ボタンクリック時のコールバック関数
 * @param {function} props.onRemoveClick - 削除ボタンクリック時のコールバック関数
 * @param {function} props.onCompleteClick - 完了ボタンクリック時のコールバック関数
 * @param {function} props.onRevertClick - 元に戻すボタンクリック時のコールバック関数
 * @returns {React.Element} プロジェクトカードコンポーネント
 */
const ProjectCard = ({ project, onEditClick, onRemoveClick, onCompleteClick, onRevertClick }) => {
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);

  const handleCompleteClick = () => {
    onCompleteClick(project);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Typography variant="h6" component="div">
              {project.name}
            </Typography>
            <Typography variant="body2" color="text.primary" sx={{ mt: 1, whiteSpace: 'pre-wrap' }}>
              <ReactMarkdown>{project.description}</ReactMarkdown>
            </Typography>
            <Typography variant="body2" color="text.primary" sx={{ mt: 1 }}>
              開始日: {project.start_date ? format(new Date(project.start_date), 'yyyy/MM/dd') : '未設定'}
            </Typography>
            <Typography variant="body2" color="text.primary" sx={{ mt: 1 }}>
              終了日: {project.end_date ? format(new Date(project.end_date), 'yyyy/MM/dd') : '未設定'}
            </Typography>
            <Typography variant="body2" color="text.primary" sx={{ mt: 1 }}>
              ステータス: {project.is_completed ? '完了' : '進行中'}
            </Typography>
            {project.is_completed && project.complete_date && (
              <Typography variant="body2" color="text.primary" sx={{ mt: 1 }}>
                完了日: {format(new Date(project.complete_date), 'yyyy/MM/dd')}
              </Typography>
            )}
            <ReferenceLinks links={project.reference_links} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button size="small" color="error" variant="outlined" sx={{ mr: 1 }} onClick={() => onRemoveClick(project)}>
              削除
            </Button>
            <Button size="small" color="primary" variant="outlined" sx={{ mr: 1 }} onClick={() => onEditClick(project)}>
              編集
            </Button>
            {project.is_completed ? (
              <Button size="small" color="primary" variant="outlined" onClick={() => onRevertClick(project)}>
                元に戻す
              </Button>
            ) : (
              <Button size="small" color="success" variant="contained" onClick={handleCompleteClick}>
                完了
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectCard;
