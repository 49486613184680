import React from 'react';
import { profileSummaryService } from '../../services/summaryService';
import { openaiService } from '../../services/openaiService';
import SummaryGenerator from '../common/SummaryGenerator';

const ProfileSummaryGenerator = ({ goals, completedGoals, reflections, accumulatedValues }) => {
  const generateSummary = async (model, userInstructions) => {
    return await openaiService.generateProfileSummary(
      { goals, completedGoals, reflections, accumulatedValues },
      model,
      userInstructions
    );
  };

  return (
    <SummaryGenerator
      title="プロフィール要約"
      summaryService={profileSummaryService}
      targetId={null}
      generateSummary={generateSummary}
    //   defaultInstructions="特に成長のパターンと今後の可能性に焦点を当ててください"
    />
  );
};

export default ProfileSummaryGenerator;
