/**
 * @fileoverview プロジェクトデータを取得・管理するカスタムフック
 * @module useProjectData
 * @requires react
 * @requires ../utils/supabase
 * @requires ../services/projectService
 */

import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { projectService } from '../services/projectService';

/**
 * プロジェクトデータを取得・管理するカスタムフック
 * @function useProjectData
 * @returns {Object} プロジェクトデータ、ローディング状態、エラー状態、データ操作関数を含むオブジェクト
 */
export const useProjectData = () => {
  const [userId, setUserId] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) setUserId(user.id);
    };
    fetchUserId();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchProjects();
    }
  }, [userId]);

  /**
   * プロジェクトデータを取得する
   * @async
   * @function fetchProjects
   */
  const fetchProjects = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await projectService.fetchProjects(userId);
      setProjects(data || []); // データがnullの場合は空配列を設定
    } catch (err) {
      console.error('プロジェクトデータの取得に失敗しました:', err);
      setError(err.message);
      setProjects([]); // エラー時も空配列を設定
    } finally {
      setLoading(false);
    }
  };

  /**
   * 新しいプロジェクトを作成する
   * @async
   * @function handleCreateProject
   * @param {Object} projectData - 新しいプロジェクトのデータ
   */
  const handleCreateProject = async (projectData) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('ユーザーが見つかりません');
      
      const newProjectData = {
        ...projectData,
        owner_id: user.id,
        is_completed: false,
        reference_links: projectData.reference_links || []
      };
      await projectService.createProject(newProjectData);
      await fetchProjects();
    } catch (error) {
      console.error('プロジェクトの作成に失敗しました:', error);
      setError(error.message);
    }
  };

  /**
   * プロジェクトを更新する
   * @async
   * @function handleUpdateProject
   * @param {string} projectId - 更新するプロジェクトのID
   * @param {Object} updatedProject - 更新されたプロジェクトデータ
   */
  const handleUpdateProject = async (projectId, updatedProject) => {
    try {
      await projectService.updateProject(projectId, updatedProject);
      await fetchProjects();
    } catch (error) {
      console.error('プロジェクトの更新に失敗しました:', error);
      setError(error.message);
    }
  };

  /**
   * プロジェクトを削除する
   * @async
   * @function handleDeleteProject
   * @param {string} projectId - 削除するプロジェクトのID
   */
  const handleDeleteProject = async (projectId) => {
    try {
      await projectService.deleteProject(projectId);
      await fetchProjects();
    } catch (error) {
      console.error('プロジェクトの削除に失敗しました:', error);
      setError(error.message);
    }
  };

  /**
   * プロジェクトを完了する
   * @async
   * @function handleCompleteProject
   * @param {string} projectId - 完了するプロジェクトのID
   * @param {Date} completeDate - 完了日
   */
  const handleCompleteProject = async (projectId, completeDate) => {
    try {
      await projectService.completeProject(projectId, completeDate);
      await fetchProjects();
    } catch (error) {
      console.error('プロジェクトの完了に失敗しました:', error);
      setError(error.message);
      throw error; // エラーを上位に伝播させる
    }
  };

  /**
   * プロジェクトを復元する
   * @async
   * @function handleRevertProject
   * @param {string} projectId - 復元するプロジェクトのID
   */
  const handleRevertProject = async (projectId) => {
    try {
      await projectService.revertProject(projectId);
      await fetchProjects();
    } catch (error) {
      console.error('プロジェクトの復元に失敗しました:', error);
      setError(error.message);
      throw error;
    }
  };

  return {
    projects,
    loading,
    error,
    fetchProjects,
    handleCreateProject,
    handleUpdateProject,
    handleDeleteProject,
    handleCompleteProject,
    handleRevertProject
  };
};
