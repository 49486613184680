/**
 * @file AdComponent.js
 * @description Google AdSenseの広告を表示するためのReactコンポーネント
 * 
 * このコンポーネントは、Google AdSenseの広告を表示するために使用されます。
 * useEffectフックを使用して、コンポーネントがマウントされた後に広告を初期化します。
 * 
 * @requires React
 * @requires useEffect
 */

import React, { useEffect } from 'react';

/**
 * AdComponentは、Google AdSenseの広告を表示するReactコンポーネントです。
 * 
 * @returns {JSX.Element} 広告を表示するためのins要素
 */
const AdComponent = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('AdSense error:', e);
    }
  }, []);

  return (
    <ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5399888064985835"
         data-ad-slot="1234567890"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
  );
};

export default AdComponent;
