/**
 * @file PublicHeader.js
 * @description 未ログイン時のヘッダーを表示するReactコンポーネント
 * 
 * このコンポーネントは、未ログイン時のヘッダーバーを実装します。
 * ログインと登録ボタンを表示し、ランディングページへのリンクを提供します。
 * 
 * @requires React
 * @requires react-router-dom
 * @requires @mui/material
 */

import React from 'react';
import { AppBar, Toolbar, Button, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * PublicHeaderは、未ログイン時のヘッダーバーを表示するReactコンポーネントです。
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} [props.hideAuthButtons=false] - 認証ボタンを非表示にするかどうか
 * @returns {JSX.Element} ヘッダーバーを表示するAppBar要素
 */
const PublicHeader = ({ hideAuthButtons = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const buttonStyle = {
    fontSize: { xs: '0.9rem', sm: '1.1rem' },
    padding: '8px 16px',
    minWidth: '100px', // ボタンの最小幅を設定
    height: '50px', // ボタンの高さを固定
  };

  return (
    <AppBar position="fixed" color="transparent" elevation={0} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
      <Toolbar sx={{ flexDirection: isMobile ? 'column' : 'row', py: isMobile ? 2 : 0 }}>
        <Link to="/landing" style={{ flexGrow: 1, textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', marginBottom: isMobile ? '16px' : 0 }}>
          <img 
            src="/images/logo-public.png" 
            alt="RefReco Logo" 
            style={{ height: '80px', maxWidth: '300px', objectFit: 'contain' }} 
          />
        </Link>
        {!hideAuthButtons && (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button 
              color="inherit" 
              component={Link}
              to="/login"
              sx={{ 
                ...buttonStyle,
                mr: 1, 
                bgcolor: 'background.paper', 
                color: 'text.primary',
                '&:hover': {
                  bgcolor: 'background.default',
                },
                fontSize: '1.3rem', // 文字の大きさを大きく設定
                border: '1px solid',
                borderColor: 'rgba(0, 0, 0, 0.23)',
              }}
            >
              ログイン
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              component={Link}
              to="/register"
              sx={{
                ...buttonStyle,
                bgcolor: '#0131b4', // より明るい青色を使用
                color: theme.palette.common.white,
                '&:hover': {
                  bgcolor: '#1565c0', // ホバー時はやや暗めの色に
                },
                fontSize: '1.3rem', // 文字の大きさを大きく設定
                boxShadow: '0 3px 5px 2px rgba(25, 118, 210, .3)', // 影を追加してボタンを浮き上がらせる
              }}
            >
              登録
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default PublicHeader;
