import React from 'react';
import { Typography, Paper, Grid } from '@mui/material';
import GoalProgress from '../common/GoalProgress';
import { formatNumber } from '../../utils/commonUtils';

/**
 * 単位ごとの累積実績と進捗を表示するカードコンポーネント
 * 
 * @component
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.accumulatedValues - 累積値のオブジェクト
 * @param {Object} props.accumulatedValues[unitId] - 各単位の累積値情報
 * @param {string} props.accumulatedValues[unitId].unitName - 単位の名前
 * @param {number} props.accumulatedValues[unitId].total - 単位の累積合計値
 * @param {Object} props.goalTotals - 目標値の合計のオブジェクト
 * @param {number} props.goalTotals[unitId] - 各単位の目標合計値
 * @returns {React.Element} 累積実績と進捗を表示するカードコンポーネント
 */
const AccumulatedProgressCard = ({ accumulatedValues, goalTotals }) => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6">単位ごとの累積実績と進捗</Typography>
      <Grid container spacing={2}>
        {Object.entries(accumulatedValues).length > 0 ? (
          Object.entries(accumulatedValues).map(([unitId, { unitName, total }]) => {
            const totalTargetValue = goalTotals[unitId] || 0;
            const goalForProgress = {
              target_value: totalTargetValue,
              totalActualValue: total
            };
            
            return (
              <Grid item xs={12} sm={6} md={4} key={unitId}>
                <Paper elevation={1} sx={{ p: 1 }}>
                  <Typography variant="subtitle1">{unitName}</Typography>
                  <Typography variant="h6">{formatNumber(total)}</Typography>
                  <GoalProgress goal={goalForProgress} showPercentage={true} />
                  <Typography variant="caption">
                    目標: {formatNumber(totalTargetValue)}
                  </Typography>
                </Paper>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Typography>累積実績データがありません。</Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default AccumulatedProgressCard;
