/**
 * @fileoverview 目標を元に戻す確認ダイアログコンポーネント
 * 
 * このファイルは、完了した目標を未完了状態に戻す前に確認を行うためのダイアログコンポーネントを定義します。
 * ユーザーに確認を求め、目標を元の状態に戻す操作を実行します。
 * 
 * @requires React
 * @requires @mui/material
 * @requires ../../services/goalService
 */

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { goalService } from '../../services/goalService'; 

/**
 * 目標を元に戻す確認ダイアログコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.goal - 元に戻す目標オブジェクト
 * @param {Function} props.onClose - ダイアログを閉じる際のコールバック
 * @param {Function} props.onUpdate - 目標更新後のコールバック
 * @returns {React.Component} 目標を元に戻す確認ダイアログコンポーネント
 */
const RevertDialog = ({ goal, onClose, onUpdate }) => {
  const handleRevertConfirm = async () => {
    if (!goal) return;

    try {
      await goalService.revertGoal(goal.id);
      onUpdate();
      onClose();
    } catch (error) {
      console.error('目標の元に戻しに失敗しました:', error);
      alert('目標を元に戻す際にエラーが発生しました。');
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>目標を元に戻す</DialogTitle>
      <DialogContent>
        <Typography>
          この目標を未完了の状態に戻しますか？
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleRevertConfirm} color="primary">
          元に戻す
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RevertDialog;
