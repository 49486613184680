import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { differenceInMinutes } from 'date-fns';

/**
 * 日付文字列を日本のロケールでフォーマットする
 * @param {string} dateString - 日付文字列
 * @returns {string} フォーマットされた日付
 */
export const formatDate = (dateString) => {
  if (!dateString) return '期限なし';
  const date = new Date(dateString);
  return date.toLocaleDateString('ja-JP', { timeZone: 'UTC' });
};

/**
 * リンクのリストを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.links - リンクの配列
 * @param {boolean} props.isDetailPage - 詳細ページかどうか
 * @returns {JSX.Element|null} リンクのリストを含むBoxコンポーネント
 */
export const ReferenceLinks = ({ links, isDetailPage }) => {
  if (!links || links.length === 0) return null;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
      {links.map((link, index) => (
        <Tooltip key={index} title={link.title || link.url} arrow>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              window.open(link.url, '_blank', 'noopener,noreferrer');
            }}
            sx={{ color: 'primary.main', mr: 0.5 }}
          >
            <AttachFileIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};

/**
 * 現在の時間を設定する
 * @param {Function} setter - 時間を設定する関数
 */
export const setCurrentTime = (setter) => {
  const now = new Date();
  now.setSeconds(0);
  now.setMilliseconds(0);
  setter(now);
};

/**
 * スキルの選択状態を切り替える
 * @param {string} skill - スキル名
 * @param {Array} selectedSkills - 選択されたスキルの配列
 * @param {Function} setSelectedSkills - スキルの選択状態を設定する関数
 */
export const handleSkillClick = (skill, selectedSkills, setSelectedSkills) => {
  setSelectedSkills(prevSkills =>
    prevSkills.includes(skill)
      ? prevSkills.filter(s => s !== skill)
      : [...prevSkills, skill]
  );
};

/**
 * リンクを追加する
 * @param {Function} setReferenceLinks - リンクの配列を設定する関数
 */
export const handleAddLink = (setReferenceLinks) => {
  setReferenceLinks(prev => [...prev, { url: '', title: '' }]);
};

/**
 * リンクの情報を変更する
 * @param {number} index - リンクのインデックス
 * @param {string} field - 変更するフィールド名
 * @param {string} value - 新しい値
 * @param {Array} referenceLinks - リンクの配列
 * @param {Function} setReferenceLinks - リンクの配列を設定する関数
 */
export const handleLinkChange = (index, field, value, referenceLinks, setReferenceLinks) => {
  const newLinks = [...referenceLinks];
  newLinks[index][field] = value;
  setReferenceLinks(newLinks);
};

/**
 * リンクを削除する
 * @param {number} index - 削除するリンクのインデックス
 * @param {Array} referenceLinks - リンクの配列
 * @param {Function} setReferenceLinks - リンクの配列を設定する関数
 */
export const handleRemoveLink = (index, referenceLinks, setReferenceLinks) => {
  const newLinks = referenceLinks.filter((_, i) => i !== index);
  setReferenceLinks(newLinks);
};

/**
 * 反射時間を更新する
 * @param {Date} startTime - 開始時間
 * @param {Date} endTime - 終了時間
 * @param {Function} setReflectionTime - 反射時間を設定する関数
 */
export const updateReflectionTime = (startTime, endTime, setReflectionTime) => {
  if (startTime && endTime) {
    const durationInMinutes = differenceInMinutes(endTime, startTime);
    setReflectionTime(durationInMinutes > 0 ? durationInMinutes.toString() : '');
  }
};

/**
 * 数値をフォーマットする
 * @param {number} value - 数値
 * @returns {string} フォーマットされた数値
 */
export const formatNumber = (value) => {
  return Number.isInteger(value) ? value.toString() : value.toFixed(2);
};
