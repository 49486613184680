import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import theme from './theme';
import Navigation from './components/common/Navigation';
import PublicHeader from './components/common/PublicHeader';
import PrivateHeader from './components/common/PrivateHeader';
import LandingPage from './pages/LandingPage';
import ReflectionPage from './pages/ReflectionPage';
import Dashboard from './pages/Dashboard';
import GoalSettingPage from './pages/GoalSettingPage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import GoalDetailPage from './pages/GoalDetailPage';
import ProfilePage from './pages/ProfilePage';
import { supabase } from './utils/supabase';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja'; // 日本語ロケールをインポート

function App() {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const session = supabase.auth.getSession();
    setUser(session?.user ?? null);
    setIsLoggedIn(!!session?.user);

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
      setIsLoggedIn(!!session);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsLoggedIn(false);
    setUser(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {isLoggedIn ? (
              <PrivateHeader toggleMenu={toggleMenu} onLogout={handleLogout} />
            ) : (
              <Routes>
                <Route path="/login" element={<PublicHeader hideAuthButtons />} />
                <Route path="/register" element={<PublicHeader hideAuthButtons />} />
                <Route path="*" element={<PublicHeader />} />
              </Routes>
            )}
            {isLoggedIn && <Navigation isOpen={isMenuOpen} toggleMenu={toggleMenu} />}
            <Box component="main" sx={{ flexGrow: 1, pt: isLoggedIn ? 9 : 8, px: 3 }}>
              <Routes>
                {isLoggedIn ? (
                  <>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/reflection/:goalId" element={<ReflectionPage />} />
                    <Route path="/reflection/:goalId/:reflectionId" element={<ReflectionPage />} />
                    <Route path="/goal-detail/:id" element={<GoalDetailPage />} />
                    <Route path="/profile" element={<ProfilePage />} /> {/* 新しく追加 */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </>
                ) : (
                  <>
                    <Route path="/" element={<Navigate to="/landing" replace />} />
                    <Route path="/landing" element={<LandingPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="*" element={<Navigate to="/landing" replace />} />
                  </>
                )}
              </Routes>
            </Box>
          </Box>
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
