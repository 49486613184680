import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#64B5F6',
      light: '#9BE7FF',
      dark: '#2286C3',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#81C784',
      light: '#B2F2B6',
      dark: '#519657',
      contrastText: '#000000',
    },
    background: {
      default: '#F8FBFD',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 300,
      fontSize: '3rem',
    },
    h2: {
      fontWeight: 400,
      fontSize: '2.5rem',
    },
    body1: {
      fontSize: '1rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        },
      },
    },
  },
});

export default theme;