import { createClient } from '@supabase/supabase-js';

/**
 * Supabaseクライアントの作成
 * @constant {string} supabaseUrl - SupabaseのURL
 * @constant {string} supabaseAnonKey - Supabaseの匿名キー
 * @returns {Object} Supabaseクライアント
 */
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
