/**
 * @fileoverview スキルサービス
 * @description スキルの取得と追加を行うサービス
 */

import { supabase } from '../utils/supabase';

export const skillService = {
  /**
   * ユーザーのスキルを取得する
   * @param {string} userId - ユーザーID
   * @returns {Promise<Array>} スキルの配列
   * @throws {Error} データ取得に失敗した場合
   */
  async fetchSkills(userId) {
    const { data, error } = await supabase
      .from('skills')
      .select(`
        id,
        created_at,
        user_id,
        name
      `)
      .or(`user_id.is.null,user_id.eq.${userId}`)
      .order('id', { ascending: true });

    if (error) {
      console.error('スキルの取得に失敗しました:', error);
      throw error;
    }

    return data;
  },

  /**
   * 新しいスキルを追加する
   * @param {string} skillName - スキル名
   * @returns {Promise<Object>} 追加されたスキル
   * @throws {Error} 追加に失敗した場合
   */
  async addSkill(skillName) {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      throw new Error('ユーザーが認証されていません');
    }

    const { data, error } = await supabase
      .from('skills')
      .insert({ name: skillName, user_id: user.id })
      .select()
      .single();

    if (error) {
      console.error('新しいスキルの追加に失敗しました:', error);
      throw error;
    }

    return data;
  }
};
