import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Box, Typography, Paper, Grid, Button } from '@mui/material';
import { useGoalData } from '../hooks/useGoalData';
import { useReflectionData } from '../hooks/useReflectionData';
import { useActivityData } from '../hooks/useActivityData';
import GoalCard from '../components/goals/GoalCard';
import GoalDialog from '../components/goals/GoalDialog';
import RemoveDialog from '../components/goals/GoalRemoveDialog';
import CompleteDialog from '../components/goals/GoalCompleteDialog';
import RevertDialog from '../components/goals/GoalRevertDialog';
import ReflectionDialog from '../components/reflections/ReflectionDialog';
import ActivityHistory from '../components/activities/ActivityHistory';
import ReflectionTimeSeriesChart from '../components/charts/ReflectionTimeSeriesChart';
import ReflectionSummaryCard from '../components/summary/ReflectionSummaryCard';
import ReflectionSkillPieChart from '../components/charts/ReflectionSkillPieChart';
import AdComponent from '../components/common/AdComponent';
import AchievementProgressChart from '../components/charts/AchievementProgressChart';
import ReflectionRemoveDialog from '../components/reflections/ReflectionRemoveDialog';
import ReflectionPage from './ReflectionPage';
import RecentReflections from '../components/reflections/RecentReflections';
import TaskList from '../components/tasks/TaskList';
import ReflectionSummaryGenerator from '../components/summary/ReflectionSummaryGenerator';

const GoalDetailPage = ({ id, onClose }) => {
  const navigate = useNavigate();
  const [selectedGoalForReflection, setSelectedGoalForReflection] = useState(null);
  const [defaultAchievement, setDefaultAchievement] = useState('');
  const { 
    goals, 
    loading: goalsLoading, 
    error: goalsError, 
    fetchGoals,
    handleEditGoal,
    handleDeleteGoal,
    handleStatusChange
  } = useGoalData(null, id);

  const { goals: allGoals } = useGoalData(); // すべての目標を取得

  const {
    reflections,
    loading: reflectionsLoading,
    error: reflectionsError,
    handleEditReflection,
    handleDeleteReflection,
    fetchReflections
  } = useReflectionData(id);

  const {
    activities,
    loading: activitiesLoading,
    error: activitiesError,
    fetchActivities
  } = useActivityData(id);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showReflectionModal, setShowReflectionModal] = useState(false);
  const [showReflectionEditModal, setShowReflectionEditModal] = useState(false);
  const [showReflectionRemoveModal, setShowReflectionRemoveModal] = useState(false);
  const [selectedReflection, setSelectedReflection] = useState(null);
  const [selectedReflections, setSelectedReflections] = useState([]);
  const [isReflectionPageOpen, setIsReflectionPageOpen] = useState(false);
  const [selectedReflectionId, setSelectedReflectionId] = useState(null);
  const [refreshReflections, setRefreshReflections] = useState(false);

  const goal = goals[0];

  const handleEditClick = () => setShowEditModal(true);
  const handleRemoveClick = () => setShowRemoveModal(true);
  const handleCompleteClick = () => setShowCompleteModal(true);
  const handleRevertClick = () => setShowRevertModal(true);
  const handleReflectionClick = (goal, reflectionId = null) => {
    setSelectedGoalForReflection(goal);
    setSelectedReflectionId(reflectionId);
    setIsReflectionPageOpen(true);
  };
  const handleEditReflectionClick = (reflection) => {
    setSelectedReflection(reflection);
    setShowReflectionEditModal(true);
  };
  const handleDeleteReflectionsClick = (reflectionIds) => {
    setSelectedReflections(reflectionIds);
    setShowReflectionRemoveModal(true);
  };
  const handleDeleteReflections = async () => {
    for (const reflectionId of selectedReflections) {
      await handleDeleteReflection({ id: reflectionId });
    }
    setShowReflectionRemoveModal(false);
    handleActivityChange();
  };

  const handleDeleteGoalAndNavigate = async () => {
    await handleDeleteGoal(goal);
    navigate('/');
  };

  const handleUpdateData = () => {
    fetchGoals();
    fetchActivities();
    setShowReflectionModal(false);
    setShowReflectionEditModal(false); // 編集ダイアログを閉じる
  };

  const handleActivityChange = () => {
    fetchGoals();
    fetchActivities();
  };

  const handleCloseReflectionPage = () => {
    setIsReflectionPageOpen(false);
    setSelectedReflectionId(null);
    handleActivityChange();
  };

  // 振り返りデータの更新関数
  const handleReflectionUpdate = () => {
    fetchReflections();
    handleActivityChange();
    setRefreshReflections(prev => !prev); // RecentReflectionsの更新をトリガー
  };

  const handleTaskComplete = (taskContent) => {
    handleReflectionClick(goal);
  };

  if (goalsLoading || reflectionsLoading) return <Typography>読み込み中...</Typography>;
  if (goalsError) return <Typography color="error">エラー: {goalsError}</Typography>;
  if (reflectionsError) return <Typography color="error">エラー: {reflectionsError}</Typography>;
  if (!goals[0]) return <Typography>目標が見つかりません。</Typography>;

  // 親目標として選択可能な目標のリストを作成
  const availableParentGoals = allGoals.filter(g => g.id !== goal.id);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="body1"
          color="primary"
          sx={{ cursor: 'pointer' }}
          onClick={onClose}
        >
          ← ダッシュボードに戻る
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <GoalCard
              goal={goal}
              isCompleted={goal.is_completed}
              onReflectionClick={() => handleReflectionClick(goal)}
              onEditClick={handleEditClick}
              onRemoveClick={handleRemoveClick}
              onCompleteClick={handleCompleteClick}
              onRevertClick={handleRevertClick}
              onStatusChange={handleStatusChange} // 新しいプロップスを追加
              hideDetailLink={true} 
              showFullDetails={true}
              childGoals={goals.filter(g => g.parent_goal_id === goal.id)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <TaskList 
            goalId={goal.id} 
            onTaskComplete={handleTaskComplete}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <ReflectionSummaryCard 
              goalID={goal.id} 
              showDetails={false}
              onEditReflection={handleEditReflectionClick}
              onDeleteReflections={handleDeleteReflectionsClick}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <ReflectionSkillPieChart goalID={goal.id} />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <ReflectionSummaryGenerator 
            goalId={goal.id} 
            reflections={reflections} 
          />
        </Grid>

        <Grid item xs={12}>
          <RecentReflections 
            reflections={reflections} 
            defaultProjectFilter={goal.project_id}
            defaultGoalFilter={goal.id}
            refreshTrigger={refreshReflections}
          />
        </Grid>

        {goal.achievement_value !== null && goal.target_value !== null && (
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <AchievementProgressChart goalID={goal.id} />
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <ReflectionTimeSeriesChart goalID={goal.id} />
          </Paper>
        </Grid>

        {/* <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, maxHeight: '75vh', overflowY: 'auto' }}>
            <ActivityHistory
              activities={activities}
              goalId={goal.id} 
              onEditGoal={handleEditGoal}
              onDeleteGoal={handleDeleteGoal}
              onEditReflection={handleEditReflection}
              onDeleteReflection={handleDeleteReflection}
              onActivityChange={handleActivityChange} 
            />
          </Paper>
        </Grid> */}
      </Grid>

      <Box sx={{ my: 4 }}>
        <AdComponent />
      </Box>

      {showEditModal && (
        <GoalDialog
          goal={goal}
          onClose={() => setShowEditModal(false)}
          onSave={(updatedGoal) => {
            handleEditGoal(updatedGoal);
            setShowEditModal(false);
            handleUpdateData();
          }}
          availableParentGoals={availableParentGoals}
        />
      )}
      {showRemoveModal && (
        <RemoveDialog
          goal={goal}
          onClose={() => setShowRemoveModal(false)}
          onRemove={handleDeleteGoalAndNavigate}
        />
      )}
      {showCompleteModal && (
        <CompleteDialog
          goal={goal}
          onClose={() => setShowCompleteModal(false)}
          onUpdate={handleUpdateData}
        />
      )}
      {showRevertModal && (
        <RevertDialog
          goal={goal}
          onClose={() => setShowRevertModal(false)}
          onUpdate={handleUpdateData}
        />
      )}
      {showReflectionModal && (
        <ReflectionDialog
          goal={goal}
          onClose={() => setShowReflectionModal(false)}
          onSave={handleReflectionUpdate} // この関数を使用
          open={showReflectionModal}
          onActivityChange={handleActivityChange} 
        />
      )}
      {showReflectionEditModal && (
        <ReflectionDialog
          goal={goal}
          reflection={selectedReflection}
          onClose={() => {
            setShowReflectionEditModal(false);
            setSelectedReflection(null); // 選択された振り返りをリセット
          }}
          onSave={(updatedReflection) => {
            handleEditReflection(updatedReflection);
            handleReflectionUpdate();
          }}
          open={showReflectionEditModal}
          onActivityChange={handleActivityChange}
        />
      )}
      {showReflectionRemoveModal && (
        <ReflectionRemoveDialog
          reflections={selectedReflections}
          onClose={() => setShowReflectionRemoveModal(false)}
          onRemove={handleDeleteReflections}
          onActivityChange={handleActivityChange}
        />
      )}
      <ReflectionPage
        isOpen={isReflectionPageOpen}
        onClose={handleCloseReflectionPage}
        goalId={selectedGoalForReflection?.id}
        reflectionId={selectedReflectionId}
      />
    </Box>
  );
};

export default GoalDetailPage;
